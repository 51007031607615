<template>
  <el-dialog v-model="isShowList" width="1200px" :destroy-on-close="true">
    <template #header>
      商户通道
    </template>
    <div class="table-head">
      <span class="table-title">{{ merchantInfo.merchant_name }}</span>
    </div>
    <div id="table">
      <c-table ref="table" :tableDataOrigin="tableDataOrigin" :isShowPagination="false" @rowClassName="rowClassName">
        <el-table-column type="index" width="80" label="序号" />
        <el-table-column prop="name" label="通道名称" />
        <el-table-column prop="code" label="通道编码" />
        <!-- <el-table-column prop="fee_rate" label="结算费率" /> -->
        <el-table-column prop="settlement_type" label="结算周期" />
        <el-table-column label="从商户账款扣款">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.deduct_type">是</el-tag>
            <el-tag type="danger" v-else>否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag type="warning" v-if="scope.row.status == 5" @click="editConfig(scope.row)"
              style="cursor:pointer">开通中</el-tag>
            <el-tag type="success" v-else-if="scope.row.status == 10" @click="editConfig(scope.row)"
              style="cursor:pointer">开通成功</el-tag>
            <el-tag type="danger" v-else-if="scope.row.status == 15" @click="editConfig(scope.row)"
              style="cursor:pointer">开通失败</el-tag>
            <el-tag type="warning" v-else-if="scope.row.status == 20" @click="editConfig(scope.row)"
              style="cursor:pointer">已提交</el-tag>
            <el-tag type="success" v-else-if="scope.row.status == 30" @click="editConfig(scope.row)"
              style="cursor:pointer">提交成功</el-tag>
            <el-tag type="danger" v-else-if="scope.row.status == 40" @click="editConfig(scope.row)"
              style="cursor:pointer">提交失败</el-tag>
            <el-tag type="warning" v-else-if="scope.row.status == 50" @click="editConfig(scope.row)"
              style="cursor:pointer">人工审核</el-tag>
            <el-tag type="warning" v-else-if="scope.row.status == 60" @click="editConfig(scope.row)"
              style="cursor:pointer">审核中</el-tag>
            <el-tag type="success" v-else-if="scope.row.status == 70" @click="editConfig(scope.row)"
              style="cursor:pointer">审核通过</el-tag>
            <el-tag type="danger" v-else-if="scope.row.status == 80" @click="editConfig(scope.row)"
              style="cursor:pointer">审核驳回</el-tag>
            <el-tag type="info" v-else>未开通</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否开启">
          <template #default="scope">
            <!-- <el-switch v-model="scope.row.auto_open" :active-value='1'  :inactive-value='0' active-text="是" inactive-text="否" inline-prompt @change="handleChangeStatus($event,scope.row)" /> -->
            <el-switch v-model="scope.row.auto_open" :active-value='1' :inactive-value='0' active-text="是"
              inactive-text="否" inline-prompt @click="handleChangeStatus(scope.row)"
              :disabled="scope.row.auto_open === 0 ? statusOpen : false" />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-popconfirm v-if="scope.row.status" :title="'确定要清除 ' + scope.row.name + ' 配置吗?'"
              @confirm=deletePaymentChannels(scope.row)>
              <template #reference>
                <el-link type="primary">清除配置</el-link>
              </template>
            </el-popconfirm>
            <el-link type="primary" v-else @click="openConfig(scope.row)">开通配置</el-link>
          </template>
        </el-table-column>
      </c-table>
    </div>

    <el-dialog v-model="isShowConfig" width="1200px" :destroy-on-close="true" :draggable="true" @close="closeForm">
      <template #header>
        通道配置 - {{ channelInfo.name }}
      </template>

      <el-form ref="configForm" label-width="180px" :model="formData" :rules="rules">

        <el-row>
          <el-col :span="16" :offset="2">
            <el-form-item label="商户号:" prop="merchant_id">
              <el-input v-model="formData.merchant_id" />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 平安（税票通） -->
        <template v-if="channelInfo.code == 'shuipiaotong'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="企业注册码:" prop="enterprise_reg">
                <el-input v-model="formData.enterprise_reg" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="邀请码:" prop="invite_code">
                <el-input v-model="formData.invite_code" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <!-- 电信（好码齐） -->
        <template v-if="channelInfo.code == 'haomaqi'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="APPID:" prop="app_id">
                <el-input v-model="formData.app_id" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="APPSECRECT:" prop="app_secret">
                <el-input v-model="formData.app_secret" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="OPENID:" prop="open_id">
                <el-input v-model="formData.open_id" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="设备型号:" prop="model_num">
                <el-input v-model="formData.model_num" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="设备号:" prop="device_num">
                <el-input v-model="formData.device_num" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="设备类型:" prop="device_type">
                <el-input v-model="formData.device_type" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="生产厂商:" prop="manufacturer">
                <el-input v-model="formData.manufacturer" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <!-- 乐刷付 -->
        <template v-if="channelInfo.code == 'fbpay'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="appId:" prop="app_id">
                <el-input v-model="formData.config.app_id" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="orderSn:" prop="order_sn">
                <el-input v-model="formData.order_sn" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="secrect:" prop="secrect">
                <el-input v-model="formData.secrect" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <!-- 云卓全景 -->
        <template v-if="channelInfo.code == 'yunzhuoquanjing'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="设备号SN:" prop="sn">
                <el-input v-model="formData.sn" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="开发者账号[devId]:" prop="dev_id">
                <el-input v-model="formData.dev_id" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="加密密钥[aesKey]:" prop="key_str">
                <el-input v-model="formData.key_str" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="接入商私钥[privateKey]:" prop="private_key">
                <el-input type="textarea" v-model="formData.private_key" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="平台公钥[publicKey]:" prop="yzqj_public_key">
                <el-input type="textarea" v-model="formData.yzqj_public_key" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <!-- 建设银行 -->
        <template v-if="channelInfo.code == 'ccbpay'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="柜台号:" prop="posid">
                <el-input v-model="formData.posid" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="分行号:" prop="branchid">
                <el-input v-model="formData.branchid" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="商户公钥:" prop="publicKey">
                <el-input v-model="formData.publicKey" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <!-- lakala -->
        <template v-if="channelInfo.code == 'lakala'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="termNo:" prop="termNo">
                <el-input v-model="formData.termNo" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <!-- 东莞银行 -->
        <template v-if="channelInfo.code == 'bankofdongguan'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="终端号:" prop="termNo">
                <el-input v-model="formData.termNo" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <!-- 随行付 -->
        <template v-if="channelInfo.code == 'vbill'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="微信子商户号subMchId:" prop="sub_mchid">
                <el-input v-model="formData.sub_mchid" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="微信子商户appid:" prop="sub_appid">
                <el-input v-model="formData.sub_appid" />
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <!-- postpay -->
        <template v-if="channelInfo.code == 'postpay'">
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="签名私钥" prop="privateKey">
                <el-input v-model="formData.privateKey" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" :offset="2">
              <el-form-item label="商户应用授权操作:" required>
                <el-button type="primary" :icon="Connection" @click="requestAuthPostPay">发起请求</el-button>
                <el-button type="plain" :icon="Search" @click="queryAuthPostPay">查询状态</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <paymentMethod ref="methods" :methods="channelInfo.payment_methods" :isShowExtraCost="false" />

      </el-form>
      <template #footer>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </template>

    </el-dialog>

  </el-dialog>
</template>

<script setup>
import { getCurrentInstance, ref, defineExpose } from 'vue'
import cTable from '@/components/CustomTable'
import paymentMethod from '@/components/CustomPaymentMethod'
import { ElMessage } from 'element-plus';
import { Connection, Search } from "@element-plus/icons-vue";

const { proxy } = getCurrentInstance() // 当前实例

const table = ref(null)

const isShowList = ref(false)
const isShowConfig = ref(false)
const merchantInfo = ref(null)
const channelInfo = ref(null)
const tableDataOrigin = ref(null)
// const methods = ref(null)
const tempTable = ref([])
const isConfigEdit = ref(false)

const formData = ref({
  id: '',
  merchant_id: '',
  enterprise_reg: '',
  invite_code: '',
  app_id: "",
  app_secret: "",
  device_num: "",
  device_type: "",
  secrect: "",
  branchid: '',
  posid: '',
  manufacturer: '',
  model_num: '',
  open_id: '',
  order_sn: '',
  publicKey: ''
})

const message = '此项为必填'

const rules = ref({
  merchant_id: [
    { required: true, message: message },
  ],
  enterprise_reg: [
    { required: true, message: message },
  ],
  invite_code: [
    { required: true, message: message },
  ],
  app_id: [
    { required: true, message: message },
  ],
  app_secret: [
    { required: true, message: message },
  ],
  open_id: [
    { required: true, message: message },
  ],
  model_num: [
    { required: true, message: message },
  ],
  device_num: [
    { required: true, message: message },
  ],
  device_type: [
    { required: true, message: message },
  ],
  manufacturer: [
    { required: true, message: message },
  ],
  order_sn: [
    { required: true, message: message },
  ],
  secrect: [
    { required: true, message: message },
  ],
  posid: [
    { required: true, message: message },
  ],
  branchid: [
    { required: true, message: message },
  ],
  publicKey: [
    { required: true, message: message },
  ],
  sn: [
    { required: true, message: message },
  ],
  dev_id: [
    { required: true, message: message },
  ],
  key_str: [
    { required: true, message: message },
  ],
  private_key: [
    { required: true, message: message },
  ],
  yzqj_public_key: [
    { required: true, message: message },
  ],
})

const rowClassName = (row) => {
  tempTable.value[row.row.code] = row.rowIndex
}

// 清空商户号
const changeChannelStatus = (status) => {
  const rowIndex = tempTable.value[channelInfo.value.code]
  tableDataOrigin.value[rowIndex].status = status
}

const closeForm = () => {
  proxy.$refs.configForm.resetFields()
  formData.value = {}
}

const openConfig = (channel) => {
  openConfig
  isShowConfig.value = true
  channelInfo.value = channel
  formData.value.payment_channel_id = channel.id
  formData.value.id = merchantInfo.value.id
}

// 编辑配置
const editConfig = (channel) => {
  isConfigEdit.value = true
  formData.value.id = merchantInfo.value.id
  formData.value.payment_channel_id = channel.id
  const params = {
    payment_channel_id: channel.id,
    merchant_id: merchantInfo.value.id
  }
  proxy.$api.payment.queryConfigInfo(params).then(r => {
    if (r.status == 200) {
      const payment_methods = r.data.data[0].payment_methods
      const config = r.data.data[0].config
      let new_payment_methods = [0]
      payment_methods.forEach(element => {
        channel.payment_methods.forEach(elem => {
          if (elem.id === element.payment_method_id) {
            elem.pivot.cost = element.cost
            new_payment_methods[elem.id] = elem
          }
        })
      })
      for (let obj in config) {
        formData.value[obj] = config[obj]
      }
      channelInfo.value = channel
      isShowConfig.value = true
    }
  })
}

const submit = () => {
  proxy.$refs.configForm.validate((validate) => {
    if (validate) {
      let config = {}
      config['merchant_id'] = formData.value.merchant_id
      switch (channelInfo.value.code) {
        case 'vbill':
          config['mno'] = formData.value.merchant_id
          config['sub_mchid'] = formData.value.sub_mchid
          config['sub_appid'] = formData.value.sub_appid
          break;
        case 'shuipiaotong':
          config['enterprise_reg'] = formData.value.enterprise_reg
          config['invite_code'] = formData.value.invite_code
          break;
        case 'haomaqi':
          config['app_id'] = formData.value.app_id
          config['app_secret'] = formData.value.app_secret
          config['device_num'] = formData.value.device_num
          config['device_type'] = formData.value.device_type
          config['manufacturer'] = formData.value.manufacturer
          config['model_num'] = formData.value.model_num
          config['open_id'] = formData.value.open_id
          break;
        case 'fbpay':
          config['app_id'] = formData.value.app_id
          config['order_sn'] = formData.value.order_sn
          config['secrect'] = formData.value.secrect
          break;
        case 'ccbpay':
          config['branchid'] = formData.value.branchid
          config['posid'] = formData.value.posid
          config['publicKey'] = formData.value.publicKey
          break;
        case 'lakala':
          config['termNo'] = formData.value.termNo
          break;
        case 'bankofdongguan':
          config['termNo'] = formData.value.termNo
          break;
        case 'postpay':
          config['privateKey'] = formData.value.privateKey
          break;
        case 'yunzhuoquanjing':
          config['sn'] = formData.value.sn
          config['dev_id'] = formData.value.dev_id
          config['key_str'] = formData.value.key_str
          config['private_key'] = formData.value.private_key
          config['yzqj_public_key'] = formData.value.yzqj_public_key
          break;
      }

      formData.value.config = config
      const method = proxy.$refs.methods.sendMethodForm()
      if (!method) {
        ElMessage({ message: '费率不合法' })
        return false
      }

      formData.value.payment_methods = method
      if (isConfigEdit.value) {
        // 编辑
        proxy.$api.payment.openPaymetConfig(formData.value.id, formData.value).then((r) => {
          setRes(r)
        })
      } else {
        // 保存
        proxy.$api.payment.openPaymetConfig(formData.value.id, formData.value).then((r) => {
          setRes(r)
        })
      }
    }
  })
}

const setRes = (res) => {
  if (res.status == 200) {
    ElMessage({
      message: '操作成功',
      type: 'success',
    })
    formData.value.config = {}
    isShowConfig.value = false
    changeChannelStatus(1)
  } else {
    ElMessage.error('操作失败，请稍后重试')
  }
}

const cancel = () => {
  isShowConfig.value = false
}

// 清除配置
const deletePaymentChannels = (channel) => {
  const params = { id: merchantInfo.value.id, payment_channel_id: channel.id }
  proxy.$api.payment.delPatmentInfo(merchantInfo.value.id, params).then((r) => {
    if (r.status == 200) {
      changeChannelStatus(null)
      queryPayMentlist(merchantInfo.value.id, { id: merchantInfo.value.id })
    }
  })
}

const requestAuthPostPay = () => {
  proxy.$api.payment.postpayRequestAuth({
    shop_id: formData.value.merchant_id
  }).then((r) => {
    if (r.status == 200) {
      ElMessage({
        message: '操作成功',
        type: 'success',
      })
    }
  })
}
const queryAuthPostPay = () => {
  proxy.$api.payment.postpayQueryAuth({
    shop_id: formData.value.merchant_id
  }).then((r) => {
    if (r.status == 200) {
      console.log('postpay res=', r)
      ElMessage({
        message: r.data.data.status == 'success' ? '已成功授权' : '等待商户确认授权中...',
        type: 'success',
      })
    }
  })
}


const open = (item) => {
  isShowList.value = true
  merchantInfo.value = item
  queryPayMentlist(item.id, { id: item.id })
}

const statusOpen = ref()
const queryPayMentlist = (id, data) => {
  proxy.$api.payment.querypaymentlist(id, data).then((r) => {
    tableDataOrigin.value = r.data.data

    statusOpen.value = tableDataOrigin.value.some(item => item.auto_open === 1);
    table.value.setTableData(r.data.data)
  })
}

defineExpose({ open })

const handleChangeStatus = (params) => {

  proxy.$api.payment.chengStatus({
    payment_channel_id: params.id,
    merchant_id: merchantInfo.value.id,
    auto_open: params.auto_open
  }).then(res => {
    if (res.status == 200) {
      ElMessage({ message: '操作成功', type: 'success' })
      queryPayMentlist(merchantInfo.value.id, { id: merchantInfo.value.id })
    } else {
      ElMessage.error('操作失败')
      queryPayMentlist(merchantInfo.value.id, { id: merchantInfo.value.id })
    }
  }).catch(err => {
    if (err.response.data.status == 500 && err.response.data.error.message) {
      ElMessage({ message: err.response.data.error.message, type: 'warning' })
      queryPayMentlist(merchantInfo.value.id, { id: merchantInfo.value.id })
    } else {
      ElMessage.error('服务器内部错误')
      queryPayMentlist(merchantInfo.value.id, { id: merchantInfo.value.id })
    }
  })


}
</script>

<style lang="less" scoped>
.table-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.el-card__header {
  background-color: #fafafa !important;
}

.per_cent {
  text-align: center;
  line-height: 32px;
}
</style>
