<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import dayjs from "dayjs"
import Upload from "../../components/CustomUpload"
import { ElMessage } from 'element-plus'

const adData = ref([
  {
    agent_id: 19,
    bidding: "0.00",
    category: 0,
    clicks: 0,
    created_at: "2022-09-22T13:04:23.000000Z",
    id: 1,
    image_link: "https://www.baidu.com",
    image_url: "https://t7.baidu.com/it/u=4198287529,2774471735&fm=193&f=GIF",
    updated_at: "2022-09-22T13:04:23.000000Z",
    view_type: 0,
    weight: 0,
  }
])

const { appContext } = getCurrentInstance()


const editShow = ref(false)

const editData = ref()

const ruleEditRef = ref()

const handleEdit = (row) => {
  editData.value = row
  editShow.value = true
}


const hanldeEditSeve = async (El) => {
  if (!El) return
  await El.validate((valid) => {
    if (valid) {
      console.log(editData.value);
    }
  })
}

const handleDelete = (row) => {
  appContext.config.globalProperties.$api.ad.del(row.id).then(r => {
    if (r.status == 200) {
      console.log(r);
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
    }
  })
}

const pageinfo = ref({
  count: 2,
  currentPage: 1,
  links: [],
  perPage: 10,
  total: 1,
  totalPages: 1,
})

const queryAdList = (data) => {
  let d = { pageSize: 15 }

  if (data != null) {
    d.pageSize = data
  }

  appContext.config.globalProperties.$api.ad.query(d).then(r => {
    if (r.status == 200) {
      console.log(r);
    }
  })
}


const pageChange = (page) => {
  console.log(page);
  queryAdList(page)
}

onMounted(() => {
  queryAdList()
})


const isshowNew = ref(false)

const handleNewAd = () => {
  isshowNew.value = !isshowNew.value
}

const NewAdData = ref({})

const ruleNewRef = ref()

const hanldeSeveAd = async (El) => {
  if (!El) return
  await El.validate((valid) => {
    if (valid) {
      console.log('submit!')
      console.log(NewAdData.value);
    }
  })
}


const rules = {
  image_url: [
    { required: true, message: '请上传图片', trigger: 'change' },
  ],
  image_link: [
    { required: true, message: '链接地址不能为空', trigger: 'change' },
  ],
  weight: [
    { required: true, message: '权重不能为空', trigger: 'change' },
  ],
  bidding: [
    { required: true, message: '竞价不能为空', trigger: 'change' },
  ],
  category: [
    { required: true, message: '类别不能为空', trigger: 'change' },
  ],
  view_type: [
    { required: true, message: '显示页面不能为空', trigger: 'change' },
  ]
}


const uploadPic = (id, url) => {
  NewAdData.value.image_url = url
  NewAdData.value.image_id = id
}
</script>


<template>
  <div class="container">
    <div class="topbar">
      <el-button type="primary" @click="handleNewAd">新增广告</el-button>
    </div>

    <el-dialog v-model="isshowNew" title="新增广告" width="40%">
      <el-form :model="NewAdData" label-position="right" :label-width="80" :rules="rules" ref="ruleNewRef">
        <el-form-item label="广告图片" prop="image_url">
          <Upload @success="uploadPic" text="广告图片" />
        </el-form-item>
        <el-form-item label="链接地址" prop="image_link">
          <el-input v-model="NewAdData.image_link" />
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input v-model="NewAdData.weight" />
        </el-form-item>
        <el-form-item label="竞价" prop="bidding">
          <el-input v-model="NewAdData.bidding" />
        </el-form-item>
        <el-form-item label="类别" prop="category">
          <el-input v-model="NewAdData.category" />
        </el-form-item>
        <el-form-item label="显示页面" prop="view_type">
          <el-input v-model="NewAdData.view_type" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="isshowNew = false">取消</el-button>
        <el-button type="primary" @click="hanldeSeveAd(ruleNewRef)">保存</el-button>
      </template>
    </el-dialog>

    <div class="mian">
      <el-table :data="adData" style="width: 100%" size="small">
        <el-table-column label="序号" width="80" type="index" align="center">
          <template #default="scope">
            {{ (pageinfo.currentPage -1) * pageinfo.perPage +scope.$index+1}}
          </template>
        </el-table-column>
        <el-table-column prop="image_url" label="	广告图片" width="200">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-image :src="scope.row.image_url" style="width:200px" :preview-src-list="[scope.row.image_url]" :z-index="999" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="image_link" label="链接地址">
          <template #default="scope">
            <el-link :underline="false" type="primary">{{scope.row.image_link}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="weight" label="权重" width="80" />
        <el-table-column prop="bidding" label="竞价" width="120" />
        <el-table-column prop="category" label="类别" width="120" />
        <el-table-column prop="view_type" label="显示页面" width="120" />
        <el-table-column prop="clicks" label="点击次数" width="120" />
        <el-table-column prop="created_at" label="创建时间" width="180">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="handleEdit(scope.row)" link>编辑</el-button>
            <el-popconfirm title="确定删除?" @confirm="handleDelete(scope.row)">
              <template #reference>
                <el-button type="danger" link>删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :total=pageinfo.total @current-change="pageChange" :page-size=pageinfo.perPage :current-page=pageinfo.currentPage />
      </div>
    </div>

    <el-dialog v-model="editShow" title="编辑广告" width="40%">
      <el-form :model="editData" label-position="right" :label-width="80" :rules="rules" ref="ruleEditRef">
        <el-form-item label="广告图片" prop="image_url">
          <Upload @success="uploadPic" text="广告图片" />
        </el-form-item>
        <el-form-item label="链接地址" prop="image_link">
          <el-input v-model="editData.image_link" />
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input v-model="editData.weight" />
        </el-form-item>
        <el-form-item label="竞价" prop="bidding">
          <el-input v-model="editData.bidding" />
        </el-form-item>
        <el-form-item label="类别" prop="category">
          <el-input v-model="editData.category" />
        </el-form-item>
        <el-form-item label="显示页面" prop="view_type">
          <el-input v-model="editData.view_type" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editShow = false">取消</el-button>
        <el-button type="primary" @click="hanldeEditSeve(ruleEditRef)">保存</el-button>
      </template>
    </el-dialog>

  </div>
</template>



<style lang="less" scoped>
.container {
  .topbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .mian {
    padding: 10px 0;

    .pagination {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>

<style>
.table__inner-wrapper::before,
.el-table__inner-wrapper::before,
.el-tabs__nav {
  z-index: 0 !important;
}
</style>