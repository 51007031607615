<script setup>
import { defineExpose, ref, getCurrentInstance, reactive, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { CopyDocument } from '@element-plus/icons-vue'
import dayjs from "dayjs"


const { proxy } = getCurrentInstance() // 当前实例

const isShowDeviceList = ref(false)
const merchantInfo = ref()

let deviceData = ref()
let pageinfo = reactive({
  total: 0,       // 总条数
  perPage: 0,     // 每页多少条
  currentPage: 0, // 当前页数
  totalPages: 0 // 总页数
})

let deviceType = ref()

const handleUnbundling = (row) => {
  let data = {
    id: row.merchant_id,
    device_ids: [row.id]
  }
  unbundDevice(row.merchant_id, data)
}

const handleStore = (row) => {
  queryStore({ merchant_id: row.merchant_id })
  storeFrom.value = row
  nextTick(() => {
    isShowDistribution.value = !isShowDistribution.value
  })
}

const handleCopy = (data) => {
  ElMessage({
    message: '复制成功',
    type: 'success',
  })
  let tempcopy = document.createElement("input")
  tempcopy.value = data
  document.body.appendChild(tempcopy)
  tempcopy.select()
  document.execCommand('Copy')
}

// 分配门店
const isShowDistribution = ref(false)
let storeFrom = ref()
let storeList = ref()

// 更改
const DistributionVisible = () => {
  let data = {
    id: storeFrom.value.id,
    store_id: storeFrom.value.store_id
  }
  sendDistribution(data)
}

// 查询已绑定设备
const bindDevice = (id, data) => {
  querDeviceType()
  proxy.$api.merchants.queryStoresBind(id, data).then(res => {
    if (res.status == 200) {
      deviceData.value = res.data.data
      pageinfo.total = res.data.pagination.total
      pageinfo.perPage = res.data.pagination.perPage
      pageinfo.currentPage = res.data.pagination.currentPage
      pageinfo.totalPages = res.data.pagination.totalPages
      deviceType.value.forEach(element => {
        deviceData.value.forEach(item => {
          // 判断设备分类
          if (element.id == item.device_category_id) {
            item.device_category_name = element.name
            // 判断设备型号
            if (element.device_types.length != 0) {
              element.device_types.map(type => {
                if (type.id == item.device_type_id) {
                  item.device_type_name = type.name
                }
              })
            }
          }
        })
      });
    } else {
      ElMessage.error('查询失败,请稍后重试！')
    }
  })
}

// 查询设备类型
const querDeviceType = () => {
  proxy.$api.agents.queryDeviceCategories().then(res => {
    if (res.status == 200) {
      deviceType.value = res.data.data
    } else {
      console.log(res);
      ElMessage.error('设备类型失败,请稍后重试')
    }
  })
}

// 查询门店列表
const queryStore = (data) => {
  proxy.$api.merchants.queryStores(data).then(res => {
    if (res.status == 200) {
      storeList.value = res.data.data
    } else {
      console.log(res);
      ElMessage.error('门店查询失败,请稍后重试')
    }
  })
}

// 分配门店
const sendDistribution = (data) => {
  console.log("发送分配门店请求");
  console.log(data);
}

// 解绑设备
const unbundDevice = (id, data) => {
  proxy.$api.merchants.storeUnDevices(id, data).then(res => {
    if (res.status == 200) {
      ElMessage({
        message: '解绑成功',
        type: 'success',
      })
      bindDevice(id, { id: id })
    } else {
      console.log(res);
      ElMessage.error('解绑失败,请稍后重试')
    }
  })
}

const open = (item) => {
  isShowDeviceList.value = true
  merchantInfo.value = item
  bindDevice(item.id, { id: item.id })
}

defineExpose({ open })
</script>

<template>
  <el-dialog v-model="isShowDeviceList" width="70%" :destroy-on-close="true">
    <template #header>
      已绑定设备列表
    </template>
    <div class="table-head">
      <span class="table-title">{{merchantInfo.merchant_name}}</span>
    </div>
    <div class="content">
      <el-table :data="deviceData" style="width: 100%">
        <el-table-column label="序号" width="80" type="index" align="center">
          <template #default="scope">
            {{ (pageinfo.currentPage -1) * pageinfo.perPage +scope.$index+1}}
          </template>
        </el-table-column>
        <el-table-column prop="serial_number" label="设备序列号">
          <template #default="scope">
            <el-tooltip :content=scope.row.serial_number placement="top">
              <div v-if="scope.row.serial_number.length>10">
                <span>{{scope.row.serial_number.substr(0,9)+'...'}}</span>
                <el-icon>
                  <CopyDocument @click="handleCopy(scope.row.serial_number)" style="color:#52acff" />
                </el-icon>
              </div>
              <div v-else>
                <span>{{scope.row.serial_number}}</span>
                <el-icon>
                  <CopyDocument @click="handleCopy(scope.row.serial_number)" style="color:#52acff" />
                </el-icon>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="secret" label="设备密钥">
          <template #default="scope">
            <el-tooltip :content=scope.row.secret placement="top">
              <div v-if="scope.row.secret.length>10">
                <span>{{scope.row.secret.substr(0,9)+'...'}}</span>
                <el-icon>
                  <CopyDocument @click="handleCopy(scope.row.secret)" style="color:#52acff" />
                </el-icon>
              </div>
              <div v-else>
                <span>{{scope.row.secret}}</span>
                <el-icon>
                  <CopyDocument @click="handleCopy(scope.row.secret)" style="color:#52acff" />
                </el-icon>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="pc_code" label="机器码" />
        <el-table-column prop="device_category_name" label="设备分类" width="120px">
          <template #default="scope">
            <el-tag>{{scope.row.device_category_name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="device_type_name" label="设备型号" width="120px">
          <template #default="scope">
            <el-tag type="warning">{{scope.row.device_type_name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="store.name" label="所属门店" />
        <el-table-column prop="created_at" label="创建时间" width="200px">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <div class="control">
              <el-link :underline="false" @click="handleUnbundling(scope.row)" type="primary">解绑</el-link>
              <el-link :underline="false" @click="handleStore(scope.row)" type="primary">分配门店</el-link>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
    </div>

    <el-dialog v-model="isShowDistribution" title="分配门店" width="30%">
      <div class="distribution">
        <el-from :model="storeFrom">
          <el-row>
            <el-col :span="18">
              <el-form-item label="门店" :rules="[{required:true,message:'此项必填'}]">
                <el-select v-model="storeFrom.store.id" placeholder="请选择门店">
                  <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-from>
        <div class="distri-footer">
          <el-button @click="isShowDistribution = false">取消</el-button>
          <el-button type="primary" @click="DistributionVisible()">确认</el-button>
        </div>
      </div>
    </el-dialog>

  </el-dialog>
</template>



<style lang="less" scoped>
.icon-box {
  display: flex;
  flex-direction: row;

  .text-overflow {
    width: 50%;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .copy {
    color: #409eff !important;
    cursor: pointer;
  }

  .copyed {
    color: #52c41a !important;
  }

  .icon {
    font-size: 14px !important;
  }
}

.content {
  margin: 10px 0;
}

.control {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.distribution {
  width: 80%;
  margin: 0 auto;
  .distri-footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>