<script setup>
import { ref, getCurrentInstance, onMounted, reactive } from "vue-demi";
import dayjs from "dayjs"
import { ElMessage } from 'element-plus'
import {CopyDocument, DocumentAdd} from '@element-plus/icons-vue'

const { appContext } = getCurrentInstance()

const qrcodeData = ref()
const loading = ref(true)

// const baseUrl = "https://offline.payment-api.duimixinyifu.com"

const queryTable = (data) => {
  loading.value = true
  appContext.config.globalProperties.$api.qrcode.queryList(data).then(r => {
    if (r.status == 200) {
      qrcodeData.value = r.data
      pagination.page = r.pagination.currentPage
      pagination.total = r.pagination.total
      pagination.perPage = r.pagination.perPage
    } else {
      ElMessage.error('查询失败')
    }
    loading.value = false
  })
}

const pagination = reactive({
  page: 1, // 当前页
  total: 0,  // 总条数
  perPage: 0  // 每页显示几条
})

const pageChange = (page) => {
  pagination.page = page
  queryList()
}

const filterBy = ref({
  created_date: null,
  name: null,
  identifying_code: null,
})
const queryList = (isReset=false) => {
  if (isReset) {
    filterBy.value = {
      created_date: null,
      name: null,
      identifying_code: null,
    }
    pagination.page = 1
    pagination.total = 0
    pagination.perPage = 0
  }
  queryTable({
    ...filterBy.value,
    page: pagination.page,
  })
}

/*
* @describe 打开并生成二维码
*/
const isShow = ref(false)

const handleGenerateCode = () => {
  appContext.config.globalProperties.$api.qrcode.generate(qrcodes).then(r => {
    if (r.status == 200) {
      ElMessage({ message: '二维码生成成功', type: 'success', })
      isShow.value = false
      queryTable()
    } else {
      ElMessage.error('二维码生成失败')
    }
  })
}

const qrcodes = reactive({
  number: 1,
  name: '',
  platform: 1,
  subsystem_id: 1
})

onMounted(() => {
  queryTable()
})

const isExporting = ref(false)
const handleExport = () => {
  if (pagination.total == 0) {
    ElMessage.info('暂无数据可导出')
    return
  }
  if (pagination.total > 5000) {
    // 弹窗确认是否继续导出
    appContext.config.globalProperties.$confirm('当前导出数量大于5000，超出部分会被忽略，确认是否继续导出？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      requestExport()
    }).catch(() => {
      ElMessage.info('已取消导出')
    })
  } else {
    requestExport()
  }
}

const requestExport = () => {
  isExporting.value = true
  console.log('is exporting = ', isExporting.value)
  appContext.config.globalProperties.$api.qrcode.export(filterBy.value, "二维码", ()=> {
    console.log('export finally', isExporting.value)
    isExporting.value = false
  })
}

const handleCopy = (data) => {
  ElMessage({
    message: '复制成功',
    type: 'success',
  })
  let tempcopy = document.createElement("input")
  tempcopy.value = data
  document.body.appendChild(tempcopy)
  tempcopy.select()
  document.execCommand('Copy')
  document.body.removeChild(tempcopy); // 复制完成后，移除临时输入框
}

</script>

<template>
  <div class="container">
    <div class="control">
      <el-form-item label="名称">
        <el-input v-model="filterBy.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="识别码">
        <el-input v-model="filterBy.identifying_code" clearable></el-input>
      </el-form-item>
      <el-form-item label="创建日期">
        <el-date-picker v-model="filterBy.created_date" type="date" value-format="YYYY-MM-DD" placeholder="选择日期" clearable/>
      </el-form-item>
      <el-button type="primary" plain @click="queryList()">查询</el-button>
      <el-button plain @click="queryList(true)">重置</el-button>
      <el-button type="warning" plain @click="handleExport()" :loading="isExporting">
        {{ isExporting ? '正在处理' : '导出二维码'}}
      </el-button>
      <el-button type="success" plain @click="isShow = !isShow" :icon="DocumentAdd">生成二维码</el-button>
    </div>
    <el-table :data="qrcodeData" style="width: 100%" v-loading="loading" size="small">
      <el-table-column prop="identifying_code" label="二维码识别码" min-width="220" show-overflow-tooltip>
        <template #default="scope">
          <span>
            <el-icon>
              <CopyDocument @click="handleCopy(scope.row.identifying_code)" style="color:#52acff" />
            </el-icon>
            {{ scope.row.identifying_code }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="适用版本" min-width="100">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.is_v2">v2 终端</el-tag>
          <el-tag type="warning" v-else>v1</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="业务" min-width="140" show-overflow-tooltip >
        <template #default="scope">
          <span>
            {{ scope.row.subsystem?.name || '通用' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" min-width="140" show-overflow-tooltip />
      <el-table-column prop="terminal_id" label="终端" min-width="120" show-overflow-tooltip />
      <el-table-column prop="url_h5" label="H5二维码" min-width="120" show-overflow-tooltip>
        <template #default="scope">
          <span>
            <el-icon v-if="scope.row.url_h5">
              <CopyDocument @click="handleCopy(scope.row.url_h5)" style="color:#52acff" />
            </el-icon>
            {{ scope.row.url_h5 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="url_v2" label="小程序二维码" min-width="120" show-overflow-tooltip>
        <template #default="scope">
          <span>
            <el-icon v-if="scope.row.url_v2">
              <CopyDocument @click="handleCopy(scope.row.url_v2)" style="color:#52acff" />
            </el-icon>
            {{ scope.row.url_v2 }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建日期" min-width="100" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD") }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="merchant_name" label="商户名称" min-width="120" show-overflow-tooltip />
      <el-table-column prop="store_name" label="门店名称" min-width="120" show-overflow-tooltip />
      <el-table-column prop="device_number" label="设备序列号" min-width="120" show-overflow-tooltip />
      <el-table-column prop="agent_name" label="代理商名称" min-width="120" show-overflow-tooltip />
      <el-table-column prop="salesman_name" label="业务员名称" min-width="120" show-overflow-tooltip />
    </el-table>
    <div class="pagination">
      <el-pagination background :page-size=15 layout="total, prev, pager, next" :total=pagination.total
        @current-change="pageChange" :current-page=pagination.page />
    </div>
    <el-dialog v-model="isShow" title="生成二维码">
      <el-form :model="qrcodes" label-width="120px">
        <el-form-item label="生成数量：" :inline="true">
          <el-input-number v-model="qrcodes.number" :min="1" :max="1000" controls-position="right" />
        </el-form-item>
        <el-form-item label="备注名称：" :inline="true">
          <el-input v-model="qrcodes.name" style="width: 220px;"/>
        </el-form-item>
        <el-form-item label="使用平台：" :inline="true">
          <el-radio-group v-model="qrcodes.platform" class="ml-4">
            <el-radio :label="1">H5</el-radio>
            <el-radio :label="2">小程序</el-radio>
            <el-radio :label="0">H5+小程序</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="业务场景：" :inline="true">
          <el-radio-group v-model="qrcodes.subsystem_id" class="ml-4">
            <el-radio :label="1">通用</el-radio>
            <el-radio :label="3">休闲乐</el-radio>
`            <el-radio :label="4">学院收款单`</el-radio>
<!--            <el-radio :label="2">测试</el-radio>-->
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleGenerateCode()">生成二维码</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.container {
  .control {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
