<script setup>
import { Plus } from '@element-plus/icons-vue'
import dayjs from "dayjs"
import { ref, getCurrentInstance, onMounted, reactive } from "vue"
import { ElMessage, ElNotification } from 'element-plus'

const isloading = ref(true)
const typeData = ref()

const { appContext } = getCurrentInstance()


const category = ref()



const queryType = () => {
  appContext.config.globalProperties.$api.device.queryDeviceType().then(r => {
    if (r.status == 200) {
      let tData = []
      r.data.data.forEach(element => {
        category.value.forEach(children => {
          if (element.device_category_id == children.id) {
            element.device_category_name = children.name
            tData.push(element)
          }
        })
      });
      typeData.value = tData
    }
    isloading.value = false
  })
}

const queryCategory = () => {
  appContext.config.globalProperties.$api.agents.queryDeviceCategories().then(re => {
    if (re.status == 200) {
      category.value = re.data.data
      queryType()
    }
  })
}

onMounted(() => {
  queryCategory()
})





const editStatus = ref(false)

const editData = ref({})

const handleEdit = (row) => {
  editData.value = { ...row }
  editStatus.value = !editStatus.value
}

const handleEditType = async (editEl) => {
  if (!editEl) return
  await editEl.validate((valid) => {
    if (valid) {
      requestChangeData(editData.value.id, editData.value)
    }
  })
}

const ruleEditRef = ref()

const rules = {
  device_category_id: [
    { required: true, message: '请选择设备分类', trigger: 'change' }
  ],
  name: [
    { required: true, message: '设备类型名称不能为空', trigger: 'change' }
  ],
  type: [
    { required: true, message: '设备类型不能为空', trigger: 'change' }
  ],
}

const requestChangeData = (id, data) => {
  appContext.config.globalProperties.$api.type.edit(id, data).then(r => {
    if (r.status == 200) {
      ElMessage({
        message: '修改成功', type: 'success'
      })
      editStatus.value = false
      queryType()
    } else {
      ElMessage({
        message: '修改失败', type: 'error'
      })
    }
  }).catch(err => {
    if (err.response.status == 500) {
      ElNotification({
        title: 'Error',
        message: err.response.data.error.message,
        type: 'error',
      })
    }
  })
}


const newData = reactive({})
const newStatus = ref(false)
const ruleNewRef = ref()

const handleNewType = async (newEl) => {
  if (!newEl) return
  await newEl.validate((valid) => {
    if (valid) {
      requestTypeData(newData)
    }
  })
}

const requestTypeData = (data) => {
  appContext.config.globalProperties.$api.type.new(data).then(r => {
    if (r.status == 200) {
      ElMessage({
        message: '添加成功', type: 'success'
      })
      newStatus.value = false
      queryType()
    } else {
      ElNotification({
        title: 'Error',
        message: r.error.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

</script>

<template>
  <div class="container">
    <div class="topbar">
      <el-button type="primary" @click="newStatus=!newStatus">
        <el-icon color="#fff">
          <Plus />
        </el-icon>
        新增类型
      </el-button>
    </div>

    <el-dialog v-model="newStatus" title="新增设备类型" width="26%">
      <el-form :model="newData" :label-width="110" :rules="rules" ref="ruleNewRef">
        <el-form-item label="设备分类" prop="device_category_id">
          <el-select v-model="newData.device_category_id" placeholder="请选择设备分类" style="width:100%">
            <el-option v-for="(item,index) in category" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="type">
          <el-input v-model="newData.type" placeholder="请输入设备类型" />
        </el-form-item>
        <el-form-item label="设备类型名称" prop="name">
          <el-input v-model="newData.name" placeholder="请输入设备类型名称" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="newData.sort" placeholder="请输入排序" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="newStatus = false">取消</el-button>
        <el-button type="primary" @click="handleNewType(ruleNewRef)">确定</el-button>
      </template>
    </el-dialog>

    <div class="content">
      <el-table :data="typeData" style="width: 100%" v-loading="isloading">
        <el-table-column type="index" label="序号" width="180" />
        <el-table-column property="type" label="设备类型" />
        <el-table-column property="name" label="设备类型名称" width="180" />
        <el-table-column property="device_category.name" label="设备分类" width="180">
          <template #default="scope">
            <el-tag type="warning">{{scope.row.device_category.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column property="sort" label="排序" width="180" />
        <el-table-column property="created_at" label="创建时间">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" link @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
    </div>

    <el-dialog v-model="editStatus" title="修改设备类型" width="26%">
      <el-form :model="editData" :label-width="110" :rules="rules" ref="ruleEditRef">
        <el-form-item label="设备分类" prop="device_category_id">
          <el-select v-model="editData.device_category_id" placeholder="请选择设备分类" style="width:100%">
            <el-option v-for="(item,index) in category" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="type">
          <el-input v-model="editData.type" placeholder="请输入设备类型" />
        </el-form-item>
        <el-form-item label="设备类型名称" prop="name">
          <el-input v-model="editData.name" placeholder="请输入设备类型名称" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="editData.sort" placeholder="请输入排序" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editStatus = false">取消</el-button>
        <el-button type="primary" @click="handleEditType(ruleEditRef)">确定</el-button>
      </template>
    </el-dialog>

  </div>
</template>

<style lang="less" scoped>
.container {
  .topbar {
    display: flex;
    justify-content: flex-end;
  }
}
</style>