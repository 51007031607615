<script setup>
import { Plus } from '@element-plus/icons-vue'
import { ref, getCurrentInstance, onMounted, reactive } from 'vue-demi'
import dayjs from "dayjs"
import { ElMessage, ElNotification } from 'element-plus'

const { appContext } = getCurrentInstance()

const categoryData = ref()

const isloading = ref(true)

const queryCategories = () => {
  appContext.config.globalProperties.$api.agents.queryDeviceCategories().then(re => {
    if (re.status == 200) {
      categoryData.value = re.data.data
    }
    isloading.value = false
  })
}

onMounted(() => {
  queryCategories()
})

const handleEdit = (row) => {
  editCategory.value = { ...row }
  editStatus.value = !editStatus.value
}

const handleDel = (row) => {
  deleteCategory(row.id)
}

const AddStatus = ref(false)

const handleOpenAdd = () => {
  AddStatus.value = !AddStatus.value
}

const newCategory = reactive({})

const handleAddCategory = async (el) => {
  if (!el) return
  await el.validate((valid) => {
    if (valid) {
      addCategory(newCategory)
    }
  })
}

const addCategory = (d) => {
  appContext.config.globalProperties.$api.device.new(d).then(r => {
    if (r.status == 200) {
      ElMessage({ message: '新增成功', type: 'success' })
      AddStatus.value = false
      queryCategories()
    } else {
      ElMessage({ message: '新增失败.', type: 'error' })
    }
  }).catch(err => {
    ElNotification({
      title: '新增失败',
      message: err.response.data.error.fields,
      type: 'error',
    })
  })
}

const rules = {
  name: [
    { required: true, message: '分类名称不能为空', trigger: 'change' }
  ],
  type: [
    { required: true, message: '请选择是否为插件', trigger: 'change' }
  ]
}

const categoryRef = ref()



const editStatus = ref(false)

const editCategory = ref()

const handleEditSeve = () => {
  let d = { id: editCategory.value.id, name: editCategory.value.name, sort: editCategory.value.sort }
  appContext.config.globalProperties.$api.device.edit(d.id, d).then(re => {
    if (re.status == 200) {
      ElMessage({ message: '修改成功', type: 'success' })
      editStatus.value = false
      queryCategories()
    } else {
      ElMessage({ message: '修改失败.', type: 'error' })
    }
  })
}

const deleteCategory = (id) => {
  appContext.config.globalProperties.$api.device.del(id).then(res => {
    if (res.status == 200) {
      queryCategories()
    } else {
      ElMessage({ message: '删除失败', type: 'error' })
    }
  })
}

</script>


<template>
  <div class="container">
    <div class="topbar">
      <el-button type="primary" @click="handleOpenAdd()">
        <el-icon color="#fff">
          <Plus />
        </el-icon>
        新增设备分类
      </el-button>
    </div>

    <el-dialog v-model="AddStatus" title="新增设备分类" width="26%">
      <el-form :model="newCategory" :label-width="100" :rules="rules" ref="categoryRef">
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="newCategory.name" placeholder="请输入分类名称" />
        </el-form-item>
        <el-form-item label="是否插件" prop="type">
          <el-radio-group v-model="newCategory.type">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="newCategory.sort" placeholder="请输入排序" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="AddStatus = false">取消</el-button>
        <el-button type="primary" @click="handleAddCategory(categoryRef)">确定</el-button>
      </template>
    </el-dialog>

    <div class="content">
      <el-table :data="categoryData" style="width: 100%" v-loading="isloading">
        <el-table-column type="index" label="序号" width="180" />
        <el-table-column property="name" label="分类名称" />
        <el-table-column property="sort" label="排序" width="180" />
        <el-table-column property="created_at" label="创建时间">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template #default="scope">
            <el-button type="primary" link @click="handleEdit(scope.row)">编辑</el-button>
            <el-popconfirm title="确定删除吗?" @confirm="handleDel(scope.row)">
              <template #reference>
                <el-button type="primary" link>删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
    </div>

    <el-dialog v-model="editStatus" title="新增设备分类" width="26%">
      <el-form :model="editCategory" :label-width="70">
        <el-form-item label="分类名称">
          <el-input v-model="editCategory.name" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="editCategory.sort" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editStatus = false">取消</el-button>
        <el-button type="primary" @click="handleEditSeve()">确定</el-button>
      </template>
    </el-dialog>

  </div>
</template>

<style lang="less" scoped>
.container {
  .topbar {
    display: flex;
    justify-content: flex-end;
  }
}
</style>