<template>
  <div class="s-canvas">
    <canvas ref="canvas" :width="props.contentWidth" :height="props.contentHeight"></canvas>
  </div>
</template>

<script setup>
import { onMounted, watch, ref, nextTick } from 'vue'
import { defineProps } from 'vue'

const canvasRef = ref(null)

const props = defineProps({
  identifyCode: {
    type: String,
    default: '1234'
  },
  fontSize: {
    type: Number,
    default: 30
  },
  backgroundColor: {
    type: Number,
    default: 255
  },
  colorMin: {
    type: Number,
    default: 0
  },
  colorMax: {
    type: Number,
    default: 160
  },
  lineColorMin: {
    type: Number,
    default: 40
  },
  lineColorMax: {
    type: Number,
    default: 180
  },
  dotColorMin: {
    type: Number,
    default: 0
  },
  dotColorMax: {
    type: Number,
    default: 255
  },
  contentWidth: {
    type: Number,
    default: 112
  },
  contentHeight: {
    type: Number,
    default: 40
  }
})

const drawPic = async () => {
  await nextTick()
  const canvas = canvasRef.value
  const ctx = canvas.getContext('2d')

  const randomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const randomColor = (min, max) => {
    let r = randomNum(min, max)
    let g = randomNum(min, max)
    let b = randomNum(min, max)
    return 'rgb(' + r + ',' + g + ',' + b + ')'
  }

  const drawLine = () => {
    for (let i = 0; i < 5; i++) {
      ctx.strokeStyle = randomColor(props.lineColorMin, props.lineColorMax)
      ctx.beginPath()
      ctx.moveTo(randomNum(0, props.contentWidth), randomNum(0, props.contentHeight))
      ctx.lineTo(randomNum(0, props.contentWidth), randomNum(0, props.contentHeight))
      ctx.stroke()
    }
  }

  const drawText = (txt, i) => {
    ctx.fillStyle = randomColor(props.colorMin, props.colorMax)
    ctx.font = props.fontSize + 'px SimHei'
    let x = (i + 1) * (props.contentWidth / (props.identifyCode.length + 1))
    let y = props.contentHeight - 5
    ctx.fillText(txt, x, y)
  }

  const drawDot = () => {
    for (let i = 0; i < 80; i++) {
      ctx.fillStyle = randomColor(props.dotColorMin, props.dotColorMax)
      ctx.beginPath()
      ctx.arc(randomNum(0, props.contentWidth), randomNum(0, props.contentHeight), 1, 0, 2 * Math.PI)
      ctx.fill()
    }
  }

  ctx.textBaseline = 'bottom'
  ctx.fillStyle = randomColor(props.backgroundColor, props.backgroundColor)
  ctx.fillRect(0, 0, props.contentWidth, props.contentHeight)

  for (let i = 0; i < props.identifyCode.length; i++) {
    drawText(props.identifyCode[i], i)
  }

  drawLine()
  drawDot()
}

onMounted(() => {
  canvasRef.value = document.querySelector('canvas')
  drawPic()
})

watch(
  () => props.identifyCode,
  () => {
    drawPic()
  }
)
</script>

<style lang="less">
.s-canvas {
  cursor: pointer;
}
</style>
