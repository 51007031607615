<template>
  <el-container style="height:100%">
    <el-aside :style="{ width: elAsideWidth + 'px' }">
      <el-row style="height:100%">
        <el-col :span="24">
          <el-menu style="height:100%;border:none" @open="handleOpen" :unique-opened="true" :collapse="collapse"
            background-color="#303133" text-color="#fff" active-text-color="#6a66ff" :default-openeds="defaultOpened"
            :collapse-transition="false">
            <el-container>
              <el-main style="display:flex;font-size: 15px;">
                <img :src="logoUrl" style="margin-left: -10px; height: 50px; width: 50px;" v-if="!collapse">
                <p style="color: #fff;" v-if="!collapse">鑫易付总后台</p>
              </el-main>
            </el-container>
            <template v-for="(route, pKey) in router" :key="route.name">
              <template v-if="route.name !== 'login' && route.name !== '404' && route.name !== 'resetPassword'">
                <el-sub-menu :index="pKey + ''">
                  <template #title>
                    <el-icon>
                      <span class="iconfont" :class="route.meta.icon" style="padding-right:10px; color:#cccc"></span>
                    </el-icon>
                    <span>{{ route.name }}</span>
                  </template>
                  <template v-for="(children, cKey) in route.children" :key="children.name">
                    <el-menu-item :index="pKey + '-' + cKey" @click="selectItemMenu" v-if="children.isshow">
                      <router-link :to="children.path" style="text-decoration:none;">
                        <span style="color:#fff;width: 200px;display: block;user-select: none;">
                          {{ children.name }}
                        </span>
                      </router-link>
                    </el-menu-item>
                  </template>
                </el-sub-menu>
              </template>
            </template>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-container>
      <el-header class="header">
        <el-row style="margin-top:10px;">
          <el-col :span="1">
            <div class="line-box" @click="isShowMenu">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </el-col>
          <el-col :span="11">
            <el-breadcrumb separator=">" style="height:40px;line-height:40px">
              <el-breadcrumb-item>
                <span class="text-color">{{ breadcrumbNames[0] }}</span>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                <span class="text-color">{{ breadcrumbNames[1] }}</span>
              </el-breadcrumb-item>
            </el-breadcrumb>
          </el-col>
          <el-col :span="12" style="text-align:right">
            <el-dropdown style="margin-right:15px">
              <span class="el-dropdown-link" style="height:40px;line-height:40px">
                <span style="color:#fff">
                  {{ agentName }}
                  <el-icon>
                    <ArrowDown />
                  </el-icon>
                </span>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="logout">退出</el-dropdown-item>
                  <el-dropdown-item @click="openDialog">修改密码</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-tabs class="tabs" type="card" :model-value="isActive" closable style="margin:-20px" @tab-remove="removeTab"
          @tab-click="clickTab">
          <template v-for="(tab, index) in tabsList" :key="tab.name">
            <el-tab-pane :label="tab.title" :name="index + '-' + tab.path" style="padding:0 10px">
              <el-config-provider :locale="locale">
                <router-view :name="tab.name" v-slot="{ Component, route }">
                  <keep-alive :max="20">
                    <component :is="Component" :key="route.fullPath" />
                  </keep-alive>
                </router-view>
              </el-config-provider>
            </el-tab-pane>
          </template>
        </el-tabs>
      </el-main>
      <el-footer class="footer">
        <p>&copy; {{ Year }} 对米(广州)网络科技有限公司</p>
      </el-footer>
    </el-container>
  </el-container>
  <resetPassword :open="dialogVisible" @update:open="handleUpdateOpen" :showStoreId="false"></resetPassword>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue';
import pathTools from '@/tools/pathTools';
import { store } from '@/common/store';
import storeTools from '@/tools/storeTools';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import logoImg from '@/assets/logo.png';
import resetPassword from '@/components/reSetPassword/index.vue';

export default {
  components: {
    ArrowDown,
    resetPassword
  },
  data() {
    return {
      router: "", // 路由
      defaultOpened: [], // 路由默认开启的导航栏
      breadcrumbNames: [], // 面包屑
      tabsList: [], // 选项卡标签列表
      isActive: '', // 显示的标签
      logoUrl: logoImg, // 平台logo
      screenWidth: null, // 屏幕宽度
      collapse: false, // 是否展开或隐藏导航栏
      isNone: 'block', // 是否隐藏或显示logo文案
      elAsideWidth: 200, // 导航栏宽度
      agentName: '', // 代理商会员名称
      locale: zhCn,
      Year: new Date().getFullYear(),
      dialogVisible: false
    };
  },
  watch: {
    screenWidth(newVal) {
      this.showMenu(newVal <= 800);
    }
  },
  beforeMount() {
    this.loadPath();
    this.setDefaultOpened();
    this.setBreadcrumbNames();
    this.setTabsList();
    this.setIsActive();
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
     //屏幕尺寸变化
    window.onresize = () => {
      this.screenWidth = document.body.clientWidth;
    };
    this.updateAgentInfo();
  },
  updated() {
    this.setIsActive();
    this.setBreadcrumbNames();
  },
  methods: {
    /**
     * menu点击事件
     */
    selectItemMenu() {
      this.setBreadcrumbNames();
      this.setTabsList();
      this.setIsActive();
    },
    /**
     * 加载路由
     */
    loadPath() {
      this.router = pathTools.loadPath();
    },
    /**
     * 记录展开的路由的序号
     * @param {*} key
     */
    handleOpen(key) {
      store.setMenuIndex(key);
    },
    /**
     * 设置默认展开的路由
     */
    setDefaultOpened() {
      this.defaultOpened = [store.menuIndex];
    },
    /**
     * 设置面包屑文本
     */
    setBreadcrumbNames() {
      this.breadcrumbNames = store.getBreadcrumbNames();
    },
    /**
     * 设置tab
     */
    setTabsList() {
      this.tabsList = storeTools.getTabs();
    },
    /**
     * tab删除
     * @param {string} index
     */
    removeTab(index) {
      if (this.tabsList.length <= 1) {
        return;
      }
      const indexArr = index.split('-');
      let i = Number(indexArr[0]);

      /**
      * 删除tab标签
      */
      this.tabsList.splice(i, 1);
      storeTools.removeTab(i);

      /**
      * 如果删除的是当前选中的才重新选择tab标签项
      */
      const tabs = storeTools.getTabs();
      const nextTab = tabs[i - 1] || tabs[i] || tabs[i + 1];

      if (index === this.isActive) {
        this.$router.replace({ path: nextTab.path });
      }

      const activeArr = this.isActive.split('-');
      let ai = Number(activeArr[0]);

      if (i < ai) {
        this.isActive = (ai - 1) + '-' + activeArr[1];
      }
    },
    /**
     * 点击tab标签项目
     */
    clickTab(pane) {
      const path = pane.props.name.split('-')[1];
      this.$router.replace({ path: path });
    },
    /**
     * 设置tab默认展示标签项
     */
    setIsActive() {
      this.isActive = store.getIsActive();
    },
    isShowMenu() {
      this.showMenu(!this.collapse);
    },
    /**
     * @param {boolean} collapse true 隐藏,false 展开
     */
    showMenu(collapse) {
      this.elAsideWidth = collapse ? 64 : 200;
      this.isNone = collapse ? 'none' : 'block';
      this.collapse = collapse;
    },
    /**
     * 更新代理商信息
     */
    updateAgentInfo() {
      const agentUser = JSON.parse(sessionStorage.getItem('agent_user'));
      this.agentName = agentUser.username;
    },
    /**
     * 退出
     */
    logout() {
      sessionStorage.clear();
      this.$router.push({ path: '/login' });
      localStorage.clear();
      window.location.reload();
    },
    /*
      修改密码
    */
    openDialog() {
      this.dialogVisible = true;
    },
    handleUpdateOpen(newValue) {
      this.dialogVisible = newValue;
    }
  }
};
</script>

<style lang="less">
html,
body,
#app {
  margin: 0;
  height: 100%;
}

.header {
  background-color: #3d3d41;
  color: #fff;
}

.line-box {
  width: 100%;
  margin-left: -15px;
  cursor: pointer;

  .line {
    width: 70%;
    max-width: 30px;
    min-width: 10px;
    margin: 0 auto;
    height: 7px;
    border-bottom: 1px solid #fff;
  }

  .line:first-child {
    margin-top: 4.5px;
  }
}

.footer {
  // background-color: #e5e5f6;
  color: #999;
  text-align: center;
  font-size: 12px;
}

.text-color {
  color: #fff;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
