<script setup>
import { ref,getCurrentInstance,defineExpose} from 'vue';
import { ElNotification,ElLoading ,ElMessage} from 'element-plus'

const { appContext } = getCurrentInstance()

const isShow=ref(false)
const channelCode=ref(null)
const open = (row) => {
  isShow.value = true
  channelCode.value=row.id
  queryActivity({channel_id:row.id})
}

defineExpose({ open })


const tableData = ref([])

/* 报名 */
const handleEnroll=(row)=>{
    const loadings = ElLoading.service({
        lock: true,
        text: '报名中',
        background: 'rgba(0, 0, 0, 0.7)',
    })
   appContext.config.globalProperties.$api.shaky.open({activity_id:row.activityId,channel_id:channelCode.value}).then(r=>{
        if (r.status==200) {
            ElNotification({
                title: '报名成功',
                message: '活动申请成功',
                type: 'success',
            })
        }else{
            ElNotification({
                title: '报名失败',
                message: r.error.message,
                type: 'error',
            })
        }
        loadings.close()
   })
}

const loading=ref(true)
/* 查询活动信息 */
const queryActivity=(data)=>{
   appContext.config.globalProperties.$api.shaky.query(data).then(r=>{
     if(r.status==200){
        tableData.value=r.data.data
     }else{
        ElMessage.error('活动查询失败')
     }
     loading.value=false
   }).catch(err=>{
        ElNotification({
            title: '活动查询失败',
            message:err.response.data.error.message,
            type: 'error',
        })
        loading.value=false
   })
}

const ishowMore=ref(false)
const details=ref()
/* 查看详情 */
const hanldMore =(row)=>{
    ishowMore.value=true
    details.value=row.details
}

</script>

<template>
    <el-dialog v-model="isShow" :destroy-on-close="false" :fullscreen="false" width="70%" title="活动列表">
        <el-table :data="tableData" style="width: 100%" v-loading="loading">
            <el-table-column prop="activityId" label="活动号" width="80" />
            <el-table-column prop="activityName" label="活动名称" />
            <el-table-column label="活动时间">
                <template #default="scope">
                    <span>{{ scope.row.effectiveStart }} 至 {{ scope.row.effectiveEnd }}</span>
                </template>
            </el-table-column>
            <el-table-column label="活动规则" width="200">
                <template #default="scope">
                    <el-tag type="danger" @click="hanldMore(scope.row)">查看详情</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" :align="center" width="200px">
                <template #default="scope">
                    <div class="control">
                        <el-link :underline="false" type="success" @click="handleEnroll(scope.row)">参与活动</el-link>
                        <el-link :underline="false" type="danger" @click="handleQuery(scope.row)">推荐人查询</el-link>
                    </div>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="空数据" :image-size="200" />
            </template>
        </el-table>
        <div class="pagination">
            <!-- <el-pagination background layout=" total, prev, pager, next" :total=50 :page-size="20" /> -->
            <el-pagination background layout=" total" :total=tableData.length />
        </div>

        <el-dialog v-model="morelinner" width="70%" title="推荐人查询" append-to-body>
            查询推荐
        </el-dialog>

        <el-dialog v-model="ishowMore" width="40%" title="活动规则" append-to-body>
            <div v-html="details"></div>
        </el-dialog>
    </el-dialog>



</template>

<style scoped>
.control{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pagination{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
}
</style>