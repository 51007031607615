import http from './request'
/**
 *  api 模板
 *  login: async function (params = {}) {
        return await http.postAsync('/agent/login', params)
    }
 */
const api = {
    login: async function (params = {}) {
        return await http.postAsync('/admin/login', params)
    },
    // 图片上传
    uploadImage: function (params = {}, header) {
        return http.post('/admin/merchants/upload', params, header)
    },
    // 交易数据
    transaction: async function (params = {}) {
        return await http.getAsync("/admin/orders", params)
    },
    // 交易数据汇总
    merchantOrderDetailCollect: async function (params = {}) {
        return await http.get("/admin/orders-collect", params)
    },
    syncStatus: async function (params = {}) {
        return await http.get('/admin/orders/sync_status', params)
    },
    // 代理商列表
    agentsList: async function (params = {}) {
        return await http.getAsync("/admin/agents", params)
    },
    // 代理商子集
    agentsChild: async function (params = {}) {
        return await http.getAsync("/admin/agents/child", params)
    },
    // 添加代理商
    addAgent: async function (params = {}) {
        return await http.postAsync('/admin/agents', params)
    },
    // 查看代理商
    agentsInfo: async function (params) {
        return await http.getAsync('/admin/agents/' + params, {})
    },
    // 修改代理商
    updateAgentsInfo: function (id, params = {}) {
        return http.put('/admin/agents/' + id, params)
    },
    //查询代理商列表
    queryAgentList: function (params = {}) {
        return http.get("/admin/dict/agent_tree", params)
    },
    //查询商户
    queryMerchantList: function (params = {}) {
        return http.get("/admin/dict/merchant", params)
    },
    //查询业务员
    querySalesmenList: function (params = {}) {
        return http.get("/admin/dict/salesmen", params)
    },
    details: function (params = {}) {
        return http.get('/admin/orders/detail', params)
    },
    // 业务员列表
    salesman: {
        list: function (params = {}) {
            return http.get('/admin/salesmen', params)
        }
    },
    // 商户类别
    mcc: {
        list: function (params = {}) {
            return http.get('/admin/mccs', params)
        },
        /**获取一级行业码 */
        getGtxyTopMcc: function (params = {}) {
            return http.get('/admin/merchants/gtxy_top_mcc', params)
        },
        /**获取二级行业码 */
        getGtxySecondMcc: function (params = {}) {
            return http.get('/admin/merchants/gtxy_second_mcc', params)
        },
    },
    // 银行
    banks: {
        branchBanks: function (params = {}) {
            return http.get('/admin/branch_banks', params)
        },
        list: function (params = {}) {
            return http.get('/admin/banks', params)
        }
    },
    // 商户管理
    merchants: {
        // 新增商户
        create: function (params = {}) {
            return http.postAsync("/admin/merchants", params)
        },
        // 查询商户
        query: function (params = {}) {
            return http.get("/admin/merchants", params)
        },
        // 查询商户信息
        queryinfo: function (id) {
            return http.get(`/admin/merchants/${id}`)
        },
        // 资质补充
        supplement: function (id, params) {
            return http.postAsync(`/admin/merchants/${id}/materials`, params)
        },
        // 查询收银员列表
        queryCashiers: function (params = {}) {
            return http.get('admin/cashiers', params)
        },
        // 删除收银员
        delCashiers: function (id) {
            return http.del(`admin/cashiers/${id}`)
        },
        // 查询门店
        queryStores: function (params = {}) {
            return http.get("/admin/stores", params)
        },
        // 编辑收银员
        editCashiers: function (id, params = {}) {
            return http.post(`admin/cashiers/${id}`, params)
        },
        // 编辑门店
        editStores: function (id, params = {}) {
            return http.put(`admin/stores/${id}`, params)
        },
        //添加门店
        addStore: async function (params = {}) {
            return await http.post('/admin/stores', params)
        },
        // 商户绑定设备查询
        queryStoresBind: function (id, params = {}) {
            return http.get(`/admin/merchants/${id}/devices`, params)
        },
        // 商户解绑设备
        storeUnDevices: function (id, params = {}) {
            return http.post(`/admin/merchants/${id}/un_bind_devices`, params)
        },
        // 删除商户
        delete: function (id) {
            return http.del(`/admin/merchants/${id}`)
        },
        // 转移商户
        change: function (params = {}) {
            return http.postAsync('/admin/merchants/change', params)
        },
        // 编辑商户
        edit: function (id, params = {}) {
            // return http.post(`/admin/merchants/${id}`,params)
            return http.put(`/admin/merchants/${id}`, params)
        },
        updateSmsConfig: function (id, params = {}) {
            return http.post(`/admin/merchants/${id}/sms_config`, params)
        },
    },
    agents: {
        // 分配设备 or 查询未绑定设备
        bindDevices: function (params = {}) {
            return http.get("/admin/devices", params)
        },
        // 查询设备类型
        deviceType: function (params = {}) {
            return http.get("/admin/device_types", params)
        },
        // 查询已绑定的设备列表
        queryBind: function (id, params = {}) {
            return http.get(`/admin/agents/${id}/devices`, params)
        },
        // 解绑
        unBund: function (id, params = {}) {
            return http.postAsync(`/admin/agents/${id}/un_bind_devices`, params)
        },
        // 绑定设备
        bindingDevice: function (id, params = {}) {
            return http.postAsync(`/admin/agents/${id}/bind_devices`, params)
        },
        // 删除设备
        delDevice: function (id) {
            return http.del(`/admin/devices/${id}`)
        },
        // 编辑设备
        editorDevice: function (id, params = {}) {
            return http.put(`/admin/devices/${id}`, params)
        },
        // 查询设备分类
        queryDeviceCategories: function () {
            return http.get("/admin/device_categories")
        },
        // 新增设备
        newAddDevice: function (params = {}) {
            return http.post("/admin/devices", params)
        },
        //商户每月报表
        queryMerchantMonthlyList: function (params = {}) {
            return http.get("/admin/merchant_report/monthly_list", params)
        },
        //代理每月报表
        queryAgentMonthlyList: function (params = {}) {
            return http.get("/admin/agent_report/monthly_list", params)
        },
        //代理佣金文件上传
        upLoadFile: function (params = {}) {
            return http.post("/admin/agent_commission/upload", params)
        },
        //佣金确认列表
        commissionList: function (params = {}) {
            return http.get("/admin/agent_commission/list", params)
        },
        //佣金申请记录列表
        commissionAuditList: function (params = {}) {
            return http.get("/admin/agent_commission/list_audit_record", params)
        },
        //审核佣金
        commissionAudit: function (params = {}) {
            return http.post("/admin/agent_commission/audit", params)
        },
        //新增佣金确认
        commissionAdd: function (params = {}) {
            return http.post("/admin/agent_commission/add", params)
        },
        // 删除佣金确认
        commissionDelete: function (params = {}) {
            return http.post("/admin/agent_commission/delete", params)
        },
        //修改佣金确认
        commissionEdit: function (params = {}) {
            return http.post("/admin/agent_commission/edit", params)
        },
        //导出商户月报表
        exportMerchantCommission: async function (params = {}, filename) {
            return http.download("/admin/merchant_report/monthly_list_export", params, filename)
        },
        //导出代理月报表
        exportAgentCommission: async function (params = {}, filename) {
            return http.download("/admin/agent_report/monthly_list_export", params, filename)
        },
        //待审核
        todoList: async function (params = {}) {
            return http.get("/admin/agent_commission/todo_count", params)
        },
        apiWalletList: function (params = {}) {
            return http.get('/admin/agent_api_wallet/list', params)
        },
        apiWalletDetail: function (params = {}) {
            return http.get('/admin/agent_api_wallet/detail', params)
        },
        apiWalletAdd: function (params = {}) {
            return http.post('/admin/agent_api_wallet/add', params)
        },
        apiWalletEdit: function (params = {}) {
            return http.post('/admin/agent_api_wallet/edit', params)
        },
        apiWalletLogs: function (params = {}) {
            return http.get('/admin/agent_api_wallet/logs', params)
        }
    },
    // 支付
    payment: {
        // 支付渠道查询
        querypaymentlist: function (id, params = {}) {
            return http.get(`/admin/merchants/${id}/payment_channels`, params)
        },
        // 支付渠道列表
        paymentList: function (params = {}) {
            return http.get("/admin/payment_channels", params)
        },
        // 支付方式管理
        paymethods: function (params = {}) {
            return http.get('/admin/payment_methods', params)
        },
        // 支付方式编辑
        payeditor: function (id, params = {}) {
            return http.put(`/admin/payment_methods/${id}`, params)
        },
        // 支付渠道编辑
        paymenEditor: function (id, params = {}) {
            return http.put(`/admin/payment_channels/${id}`, params)
        },
        // 添加支付渠道
        paymentAdd: function (params = {}) {
            return http.post('/admin/payment_channels', params)
        },
        // 查询配置信息
        queryConfigInfo: function (params = {}) {
            return http.get('admin/merchants/pay_config', params)
        },
        // 清除配置信息
        delPatmentInfo: function (id, params = {}) {
            return http.post(`/admin/merchants/${id}/delete_payment_channels`, params)
        },
        // 开通配置
        openPaymetConfig: function (id, params = {}) {
            return http.post(`admin/merchants/${id}/add_payment_channels`, params)
        },
        // 修改通道状态
        chengStatus: function (params = {}) {
            return http.post('admin/merchants/edit_payment_channels/auto_open', params)
        },
        // postpay请求应用授权
        postpayRequestAuth: function (params = {}) {
            return http.post('admin/merchants/postpay_request_auth', params)
        },
        // postpay查询应用授权
        postpayQueryAuth: function (params = {}) {
            return http.post('admin/merchants/postpay_query_auth', params)
        },
    },
    // 设置费率
    setrate: async function (id, params = {}) {
        return await http.postAsync(`/admin/agents/${id}/rate`, params)
    },
    // 费率查询
    QueryRate: async function (id) {
        return await http.getAsync(`/admin/agents/${id}`)
    },
    // 发票管理
    invoice: {
        query: async function (params = {}) {
            return await http.get("/admin/invoice/billing_record", params)
        },
        modify: async function (params = {}) {
            return await http.postAsync("/admin/invoice/change_status", params)
        }
    },
    device: {
        edit: async function (id, params = {}) {
            return await http.put(`/admin/device_categories/${id}`, params)
        },
        new: async function (params = {}) {
            return await http.postAsync('/admin/device_categories', params)
        },
        del: async function (id) {
            return await http.del(`/admin/device_categories/${id}`)
        },
        queryDeviceType: async function () {
            return await http.get("/admin/device_types")
        }
    },
    thirdPartyDevice: {
        congMingPayCategory: async function () {
            return await http.get("/admin/third_party_device/congmingpay/category")
        },
        congMingPayPluginChildren: async function (params) {
            return await http.get("/admin/third_party_device/congmingpay/plugin_children", params)
        },
    },
    type: {
        edit: async function (id, params = {}) {
            return await http.put(`/admin/device_types/${id}`, params)
        },
        new: async function (params = {}) {
            return await http.postAsync("/admin/device_types", params)
        }
    },
    statistics: async function (params = {}) {
        return await http.get('/admin/dashboard/statistics', params)
    },
    // 代理商分润
    agentProfit: {
        // 查询统计
        queryCensus: async function (params = {}) {
            return await http.getAsync("/admin/agents/profit-collect", params)
        },
        // 查询表格
        query: async function (params = {}) {
            return await http.get("/admin/agents/profit", params)
        },
        exportData: async function (params = {}, filename) {
            return http.download("/admin/agents/profit/statistics_export", params, filename)
        }
    },
    ad: {
        // /agent/advertisement/list
        query: async function (params = {}) {
            return await http.get("/admin/advertisement/list", params)
        },
        new: async function (params = {}) {
            return await http.postAsync("", params)
        },
        del: async function (params = {}) {
            return await http.del("/admin/advertisement/delete", params)
        }
    },
    trade: {
        queryPanel: async function (params = {}) {
            return await http.getAsync("/admin/orders/merchant-statistics-collect", params)
        },
        queryData: async function (params = {}) {
            return await http.getAsync("/admin/orders/merchant-statistics", params)
        },
        download: async function (params = {}, filename) {
            return await http.download("admin/orders/merchant-statistics-export", params, filename)
        },
        queryAgents: async function (params = {}) {
            return await http.getAsync("/admin/agents", params)
            // return await http.getAsync("/admin/agents/depth", params)
        }
    },
    qrcode: {
        queryList: async function (params = {}) {
            return await http.getAsync("/admin/code/list", params)
        },
        generate: async function (params = {}) {
            return await http.postAsync("/admin/code/generate", params)
        },
        export: async function (params = {}, filename, fn) {
            return await http.download("/admin/code/export", params, filename, fn)
        },
        unbund: async function (params = {}) {
            return await http.postAsync('/admin/agents/un_bind_devices', params)
        },
        unbundcode: async function (params = {}) {
            return await http.postAsync('/admin/identify/un_bind_devices', params)
        }
    },
    // 活动
    shaky: {
        query: async function (params = {}) {
            return await http.get('/admin/preferential/activity', params)
        },
        open: async function (params = {}) {
            return await http.postAsync('/admin/preferential/activity-register', params)
        }
    },
    ocr: async function (params = {}) {
        return await http.postAsync('/admin/upload/ocr-discern', params)
    },
    // 代理商配置
    agentChannel: {
        query: async function (params = {}) {
            return await http.get('/admin/agent/channel/4/list', params)
        },
        new: async function (params = {}) {
            return await http.postAsync('/admin/agent/channel/4', params)
        },
        delete: async function (params = {}) {
            return await http.post('/admin/agent/channel/4/delete', params)
        },
        queryinfo: async function (params = {}) {
            return await http.get('/admin/agent/channel/4/get', params)
        },
        edit: async function (id, params = {}) {
            return await http.postAsync(`/admin/agent/channel/4/edit?id=${id}`, params)
        }
    },
    //终端
    terminals: {
        //获取终端列表
        getTerminalList: async function (params = {}) {
            return await http.get('/admin/terminals', params)
        },
        //新增终端
        addNewTerminal: async function (params = {}) {
            return await http.post('/admin/terminals', params)
        },
        //删除终端
        delTerminal: function (id) {
            return http.del(`/admin/terminals/${id}`)
        },
        //更新终端信息
        updateTerminal: function (id, params = {}) {
            return http.put(`/admin/terminals/${id}`, params)
        },
        //获取终端详情
        getTerminalDetails: async function (id) {
            return await http.get(`/admin/terminals/${id}`)
        },
        testSpeaker: async function (params = {}) {
            return await http.post('/admin/terminals/test_speaker', params)
        },
    },
    dict: {
        getDeviceDict: async function (params = {}) {
            return await http.get('/admin/dict/device', params)
        },
        getMerchantDict: async function (params = {}) {
            return await http.get('/admin/dict/merchant', params)
        },
        getAgentDict: async function (params = {}) {
            return await http.get('/admin/dict/agent', params)
        },
        getStoreDict: async function (params = {}) {
            return await http.get('/admin/dict/store', params)
        },
    },
    reSetPassword: {
        reSetAdmin: async function (params = {}) {
            return await http.post('/admin/edit_password', params)
        },
        reSetMerchant: async function (id, params = {}) {
            return await http.post(`/admin/merchants/${id}/edit_password`, params)
        },
    }
}

export default api
