<script setup>
import { ref, getCurrentInstance, onMounted, reactive } from "vue"
import dayjs from "dayjs"
import { ElMessage, ElNotification } from 'element-plus'
import { CopyDocument, Refresh } from '@element-plus/icons-vue'
import { GetTodayTime, GetTodayEndTime, timeFormat } from "../../tools/time"

const { appContext } = getCurrentInstance()

let searchInfo = ref({
  time_start: GetTodayTime(),
  time_end: GetTodayEndTime(),
  status: "default"
})

const handleReset = () => {
  searchInfo.value.created_start = GetTodayTime()
  searchInfo.value.created_end = GetTodayEndTime()
  searchInfo.value.trade_no = ""
  searchInfo.value.out_trade_no = ""
  searchInfo.value.merchant_name = ""
  searchInfo.value.store_name = ""
  searchInfo.value.agent_name = ""
  searchInfo.value.status = "default"
  searchInfo.value.merchant_no = ""
  searchInfo.value.payment_channel_id = ""
  searchInfo.value.payment_method_id = ""
}

const handleSearch = () => {
  isloading.value = true
  let info = searchInfo.value
  if (Object.keys(info).length === 0) {
    queryData()
    merchantOrderDetailCollect()
  } else {
    queryData(info)
    merchantOrderDetailCollect()
  }
}

let trade = ref()
let pageinfo = reactive({
  total: 0,       // 总条数
  perPage: 0,     // 每页多少条
  currentPage: 0, // 当前页数
  totalPages: 0  // 总页数
})
let isloading = ref(true)

let moreinfoshow = ref(false)
let moreinfo = ref()
const skeleton = ref(true)
//详情
const handleMore = (id, created_at) => {
  moreinfoshow.value = true
  appContext.config.globalProperties.$api.details({ id, created_at: timeFormat(created_at, 'date') }).then(r => {
    if (r.status == 200) {
      moreinfo.value = r.data.data
      skeleton.value = false
    }
  }).catch(() => {
    ElMessage.error('数据查询失败,请稍后重试')
  })
}

const closeOrderDetail = () => {
  skeleton.value = true
}



const queryData = (data) => {
  isloading.value = true
  if (data) {
    appContext.config.globalProperties.$api.transaction(data).then(res => {
      if (res.status === 200) {
        trade.value = res.data
        pageinfo.total = res.pagination.total
        pageinfo.perPage = res.pagination.perPage
        pageinfo.currentPage = res.pagination.currentPage
        pageinfo.totalPages = res.pagination.totalPages
      } else {
        ElMessage.error('数据查询失败,请稍后重试')
        ElNotification({
          title: '错误信息',
          message: res.message,
          type: 'error',
        })
      }
      isloading.value = false
    }).catch(() => {
      ElMessage.error('数据查询失败,请稍后重试')
      isloading.value = false
    })
  } else {
    appContext.config.globalProperties.$api.transaction().then(res => {
      if (res.status === 200) {
        trade.value = res.data
        pageinfo.total = res.pagination.total
        pageinfo.perPage = res.pagination.perPage
        pageinfo.currentPage = res.pagination.currentPage
        pageinfo.totalPages = res.pagination.totalPages
      } else {
        ElMessage.error('数据查询失败,请稍后重试')
        ElNotification({
          title: '错误信息',
          message: res.message,
          type: 'error',
        })
      }
      isloading.value = false
    }).catch(() => {
      ElMessage.error('数据查询失败,请稍后重试')
      isloading.value = false
    })
  }
}

const orderStatistics = ref({})
const merchantOrderDetailCollect = () => {
  appContext.config.globalProperties.$api.merchantOrderDetailCollect(searchInfo.value).then(res => {
    if (res.status === 200) {
      orderStatistics.value = res.data.data
    }
  }).catch(() => {
    ElMessage.error('数据查询失败,请稍后重试')
  })
}


const handleCopy = (data) => {
  ElMessage({
    message: '复制成功',
    type: 'success',
  })
  let tempcopy = document.createElement("input")
  tempcopy.value = data
  document.body.appendChild(tempcopy)
  tempcopy.select()
  document.execCommand('Copy')
  document.body.removeChild(tempcopy) // 复制完成后，移除临时输入框
}

const pageChange = (page) => {
  hasSearch(page)
}

const hasSearch = (page) => {
  let info = searchInfo.value
  if (Object.keys(info).length === 0) {
    queryData({ page: page })
  } else {
    info.page = page
    queryData(info)
  }
}


//支付渠道
const channelList = ref({})
const getpaymentChannels = () => {
  appContext.config.globalProperties.$api.payment.paymentList().then(r => {
    channelList.value = r.data.data
  }).catch(() => {
    ElMessage.error('支付渠道获取失败,请稍后重试')
  })
}

//手动检测
const syncStatus = (id, created_at) => {

  console.log(id, created_at);

  appContext.config.globalProperties.$api.syncStatus({ id, created_at: timeFormat(created_at, 'date') }).then(res => {
    if (res.status === 200) {
      ElMessage({ message: '订单状态已重新同步', type: 'success' })
      handleMore(id, created_at)
      handleSearch()
    }
  }).catch(() => {
    ElMessage.error('同步失败')
  })
}


const selectedArrData = ref([]);

// 复选框被选中的事件
const selected = (row) => {
  selectedArrData.value = row;
};

// 设置选中行的样式
const isRed = ({ row }) => {
  const checkIdList = selectedArrData.value.map((item) => item.id);
  if (checkIdList.includes(row.id)) {
    return {
      backgroundColor: "#cde8f8"
    };
  }
};

onMounted(() => {
  queryData(searchInfo.value)
  getpaymentChannels()
  merchantOrderDetailCollect()
})


</script>

<template>
  <el-card shadow="never">
    <div class="top-search">
      <el-form :inline="true" :model="searchInfo" label-width="100px" label-position="right" class="responsive-form">
        <el-row :gutter="16">
          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="交易流水号：" style="width: 100%">
              <el-input v-model="searchInfo.trade_no" placeholder="请输入交易流水号" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="商户订单号：" style="width: 100%">
              <el-input v-model="searchInfo.out_trade_no" placeholder="请输入商户订单号" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="商户名称：" style="width: 100%">
              <el-input v-model="searchInfo.merchant_name" placeholder="请输入商户名称" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="代理商名称：" style="width: 100%">
              <el-input v-model="searchInfo.agent_name" placeholder="请输入代理商名称" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="门店名称：" style="width: 100%">
              <el-input v-model="searchInfo.store_name" placeholder="请输入门店名称" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="商户号：" style="width: 100%">
              <el-input v-model="searchInfo.merchant_no" placeholder="请输入商户号" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="订单状态：" style="width: 100%">
              <el-select v-model="searchInfo.status" placeholder="请选择订单状态" clearable style="width: 100vw;">
                <el-option value="default" label="交易完成" />
                <el-option value="3" label="支付成功" />
                <el-option value="fail" label="支付失败" />
                <el-option value="5" label="部分退款" />
                <el-option value="6" label="已退款" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="支付方式：" style="width: 100%">
              <el-select v-model="searchInfo.payment_method_id" clearable style="width: 100%">
                <el-option :value="1" label="支付宝" />
                <el-option :value="2" label="微信" />
                <el-option :value="3" label="云闪付" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="支付渠道：" style="width: 100%">
              <el-select clearable v-model="searchInfo.payment_channel_id" style="width: 100%">
                <el-option v-for="item in channelList" :key="item" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="创建时间：" style="width: 100%">
              <el-date-picker v-model="searchInfo.time_start" type="datetime" format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入创建时间" style="width: 100vw;" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label="截至时间：" style="width: 100%">
              <el-date-picker v-model="searchInfo.time_end" type="datetime" format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss" placeholder="请输入截至时间" style="width: 100vw;" />
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="8" :lg="8">
            <el-form-item label=" " style="width: 100%">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="handleReset">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
  </el-card>


  <div style="display: flex; justify-content: space-between; margin-top: 20px;">
    <el-card shadow="hover" style="width: 16%;">
      <template #header>
        <div class="card-header">
          <span>订单笔数</span>
        </div>
      </template>
      <div class="card-body">
        <div>{{ orderStatistics.pay_number > 0 ? orderStatistics.pay_number : 0 }} </div>
      </div>
    </el-card>
    <el-card shadow="hover" style="width: 16%; ">
      <template #header>
        <div class="card-header">
          <span>应收金额</span>
        </div>
      </template>
      <div class="card-body">
        <!-- 应收金额= -->
        <div>{{ orderStatistics.pay_amount > 0 ? orderStatistics.pay_amount : 0 }} </div>
      </div>
    </el-card>
    <el-card shadow="hover" style="width: 16%;">
      <template #header>
        <div class="card-header">
          <span>实收金额</span>
        </div>
      </template>
      <div class="card-body">
        <!-- 请求返回的pay_amount字段是订单金额，实收金额= 订单金额 - 退款金额 -->
        <div>{{ orderStatistics.pay_amount - orderStatistics.refund_amount > 0 ?
          parseFloat(orderStatistics.pay_amount - orderStatistics.refund_amount).toFixed(2) :
          0 }}
        </div>
      </div>
    </el-card>
    <el-card shadow="hover" style="width: 16%;">
      <template #header>
        <div class="card-header">
          <span>退款金额</span>
        </div>
      </template>
      <div class="card-body">
        <div>{{ orderStatistics.refund_amount > 0 ? orderStatistics.refund_amount : 0 }} </div>
      </div>
    </el-card>
    <el-card shadow="hover" style="width: 16%;">
      <template #header>
        <div class="card-header">
          <span>手续费</span>
        </div>
      </template>
      <div class="card-body">
        <div>{{ orderStatistics.fee_amount > 0 ? orderStatistics.fee_amount : 0 }} </div>
      </div>
    </el-card>
    <el-card shadow="hover" style="width: 16%;">
      <template #header>
        <div class="card-header">
          <span>结算金额</span>
        </div>
      </template>
      <div class="card-body">
        <div>{{ orderStatistics.pay_amount - orderStatistics.refund_amount -
          orderStatistics.fee_amount > 0 ? parseFloat(orderStatistics.pay_amount -
            orderStatistics.refund_amount - orderStatistics.fee_amount).toFixed(2) : 0 }} </div>
      </div>
    </el-card>
  </div>

  <div class="content">
    <el-table :data="trade" style="width: 100%" v-loading="isloading" :border="true" :row-style="isRed"
      @selection-change="selected">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="merchant_no" label="商户号" min-width="120" />
      <el-table-column prop="merchant_name" label="商户名称" min-width="180" show-overflow-tooltip />
      <el-table-column prop="created_at" label="创建时间" min-width="200">
        <template #default="scope">
          <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD H:mm:ss") }} </span>
        </template>
      </el-table-column>
      <el-table-column prop="trade_no" label="交易流水号" min-width="200">
        <template #default="scope">
          <div class="icon-box">
            <el-tooltip effect="dark" :content="scope.row.trade_no" placement="top-start">
              <span class="text-overflow">{{ scope.row.trade_no }}</span>
            </el-tooltip>
            <span class="copy icon">
              <el-icon @click="handleCopy(scope.row.trade_no)">
                <CopyDocument />
              </el-icon>
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="out_trade_no" label="商户订单号" min-width="200">
        <template #default="scope">
          <div class="icon-box">
            <el-tooltip effect="dark" :content="scope.row.out_trade_no" placement="top-start">
              <span class="text-overflow">{{ scope.row.out_trade_no }}</span>
            </el-tooltip>
            <span class="copy icon">
              <el-icon @click="handleCopy(scope.row.out_trade_no)">
                <CopyDocument />
              </el-icon>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="agent_name" label="代理商名称" min-width="180" show-overflow-tooltip />
      <el-table-column prop="status_format" label="支付状态" min-width="120" :align="'center'">
        <template #default="scope">
          <span v-if="scope.row.status == 0">
            <el-tag type="info">取消支付</el-tag>
          </span>
          <span v-if="scope.row.status == 1">
            <el-tag type="info">用户支付中</el-tag>
          </span>
          <span v-if="scope.row.status == 2">
            <el-tag type="info">已关闭</el-tag>
          </span>
          <span v-if="scope.row.status == 3">
            <el-tag type="success">支付成功</el-tag>
          </span>
          <span v-if="scope.row.status == 4">
            <el-tag type="danger">支付失败</el-tag>
          </span>
          <span v-if="scope.row.status == 5">
            <el-tag type="warning">部分退款</el-tag>
          </span>
          <span v-if="scope.row.status == 6">
            <el-tag type="info">已退款</el-tag>
          </span>
          <span v-if="scope.row.status == 7">
            <el-tag type="info">已撤销</el-tag>
          </span>
          <span v-if="scope.row.status == 8">
            <el-tag type="info">已接收</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="pay_amount" label="支付金额" min-width="100">
        <template #default="scope">
          <span>{{ scope.row.pay_amount }} 元</span>
        </template>
      </el-table-column>
      <el-table-column prop="total_amount" label="订单金额" min-width="100">
        <template #default="scope">
          <span>{{ scope.row.total_amount }} 元</span>
        </template>
      </el-table-column>
      <el-table-column prop="refund_amount" label="退款金额" min-width="100">
        <template #default="scope">
          <span>{{ scope.row.refund_amount }} 元</span>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="is_settlement" label="是否结算" width="80">
        <template #default="scope">
          <span v-if="scope.row.is_settlement==0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="payment_channel" label="支付渠道" min-width="100" :align="'center'" />
      <el-table-column prop="payment_method" label="支付方式" min-width="100" />
      <el-table-column label="操作" :align="'center'" fixed="right">
        <template #default="scope">
          <el-link :underline="false" @click="handleMore(scope.row.id, scope.row.created_at)"
            type="primary">详情</el-link>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="空数据" :image-size="200" />
      </template>
    </el-table>
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next" :total=pageinfo.total @current-change="pageChange"
        :page-size=pageinfo.perPage :current-page=pageinfo.currentPage />
    </div>
  </div>
  <!-- more -->
  <el-dialog v-model="moreinfoshow" title="订单详情" width="77%" :show-close=false @close="closeOrderDetail">
    <el-skeleton v-if="skeleton" :rows="9" :animated='true' :loading='true' />
    <div v-else class="moreinfo">
      <el-row>
        <el-col :span="24">
          <div v-if="moreinfo.status != 3 && moreinfo.status != 5 && moreinfo.status != 6" class="col-bg">
            <el-button type="primary" :icon="Refresh" size="medium"
              @click="syncStatus(moreinfo.id, moreinfo.created_at)">
              手动检测
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            交易流水号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            <el-tooltip :content="moreinfo.trade_no" placement="top">
              {{ moreinfo.trade_no }}
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            渠道交易号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            <el-tooltip :content="moreinfo.channel_trade_no" placement="top">
              {{ moreinfo.channel_trade_no }}
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户订单号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            <el-tooltip :content="moreinfo.out_trade_no" placement="top">
              {{ moreinfo.out_trade_no }}
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户名称
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.merchant_name }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户编号
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.merchant_no }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            门店名称
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.store_name }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            代理商名称
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.agent_name }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            支付设备
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.serial_number }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            交易费率
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.rate }}%
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            手续费
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.fee_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            订单金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.total_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            支付金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.pay_amount }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            优惠金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.discount_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            商户实收金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.receipt_amount }}
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            退款金额(元)
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.refund_amount }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            订单状态
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            <el-tag v-if="moreinfo.status_format == '支付成功'" type="success" effect="dark" size="small">{{
              moreinfo.status_format }}</el-tag>
            <el-tag v-else-if="moreinfo.status_format == '支付失败'" type="danger" effect="dark" size="small">{{
              moreinfo.status_format }}</el-tag>
            <el-tag v-else type="info" effect="dark" size="small">{{ moreinfo.status_format }}</el-tag>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            是否结算
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            <el-tag v-if="moreinfo.is_settlement == 1" type="success" size="small">是</el-tag>
            <el-tag v-else type="danger" size="small">否</el-tag>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="moreinfo-title">
            支付方式
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ moreinfo.payment_method }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <div class="moreinfo-title">
            创建时间
          </div>
        </el-col>
        <el-col :span="5">
          <div class="moreinfo-content">
            {{ dayjs(moreinfo.created_at).format("YYYY-MM-DD H:mm:ss") }}
          </div>
        </el-col>

        <el-col :span="3">
          <div class="moreinfo-title">
            备注
          </div>
        </el-col>
        <el-col :span="13">
          <div class="moreinfo-content">
            {{ moreinfo.remarks }}
          </div>
        </el-col>
      </el-row>
      <!-- <el-row justify="space-evenly">
        <el-col :span="3"
          style="background-color:#FAFAFA;height:100px;line-height: 100px;padding-left: 20px;">一级代理商</el-col>
        <el-col :span="21">
          <el-row justify="space-evenly">
            <el-col :span="6" class="col-profit-title">一级代理商分润</el-col>
            <el-col :span="6" class="col-profit-title">一级应收下级分润</el-col>
            <el-col :span="6" class="col-profit-title">一级上级应收分润</el-col>
            <el-col :span="6" class="col-profit-title">一级最终收益</el-col>
          </el-row>
          <el-row justify="space-evenly">
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.first_agent_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.first_receivable_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.first_superior_receivable_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.first_last_profit
            }}</el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row justify="space-evenly">
        <el-col :span="3"
          style="background-color:#FAFAFA;height:100px;line-height: 100px;padding-left: 20px;">二级代理商</el-col>
        <el-col :span="21">
          <el-row justify="space-evenly">
            <el-col :span="6" class="col-profit-title">二级代理商分润</el-col>
            <el-col :span="6" class="col-profit-title">二级应收下级分润</el-col>
            <el-col :span="6" class="col-profit-title">二级上级应收分润</el-col>
            <el-col :span="6" class="col-profit-title">二级最终收益</el-col>
          </el-row>
          <el-row justify="space-evenly">
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.second_agent_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.second_receivable_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.second_superior_receivable_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.second_last_profit
            }}</el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row justify="space-evenly">
        <el-col :span="3"
          style="background-color:#FAFAFA;height:100px;line-height: 100px;padding-left: 20px;overflow: hidden;">三级代理商</el-col>
        <el-col :span="21">
          <el-row justify="space-evenly">
            <el-col :span="6" class="col-profit-title">三级代理商分润</el-col>
            <el-col :span="6" class="col-profit-title">三级应收下级分润</el-col>
            <el-col :span="6" class="col-profit-title">三级上级应收分润</el-col>
            <el-col :span="6" class="col-profit-title">三级最终收益</el-col>
          </el-row>
          <el-row justify="space-evenly">
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.three_agent_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.three_receivable_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.three_superior_receivable_profit
            }}</el-col>
            <el-col :span="6" class="col-profit-content">{{
              moreinfo.three_last_profit
            }}</el-col>
          </el-row>
        </el-col>
      </el-row> -->
    </div>
    <template #footer>
      <el-button type="primary" @click="moreinfoshow = false">关闭</el-button>
    </template>
  </el-dialog>
  <!-- more end -->
</template>

<style lang="less" scoped>
.top-search {
  width: 100%;

  .search-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  margin-top: 1.24rem;

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }
}

.moreinfo {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin: 0 auto;

  .moreinfo-title,
  .moreinfo-content {
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
  }

  .moreinfo-title {
    background-color: #FAFAFA;

  }

  .moreinfo-content {
    background: #fff;

  }
}


.icon-box {
  display: flex;
  flex-direction: row;

  .text-overflow {
    width: 90%;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .copy {
    color: #409EFF !important;
    cursor: pointer;
  }

  .copyed {
    color: #52c41a !important;
  }

  .icon {
    font-size: 14px !important;

  }
}

.card-body {
  font-size: 18px;
  color: var(--el-color-primary);

  div::before {
    content: "¥";
    /* 人民币符号 */
    display: inline-block;
    margin-right: 5px;
    /* 可选：如果您想在符号和文本之间添加一些空隙 */
  }
}

.col-bg {
  background-color: #FAFAFA;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.col-profit-title {
  background-color: #FAFAFA;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
}

.col-profit-content {
  background-color: #fff;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
}


/* 当鼠标悬停在表格的行上时改变背景色 */
.el-table {
  --el-table-row-hover-bg-color: #cde8f8;
}

/* src/assets/styles/global.css */
/* 滚动条整体部分 */
::v-deep .el-scrollbar__bar {
  opacity: 1;
  /* 使滚动条不透明 */
}

/* 横向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-horizontal .el-scrollbar__thumb {
  border-radius: 1px;
  /* 滑块的圆角 */
  background-color: #818181;
  /* 滑块的背景颜色 */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  /* 滑块的阴影 */
}

/* 纵向滚动条的滑块 */
::v-deep .el-scrollbar__bar.is-vertical .el-scrollbar__thumb {
  border-radius: 1px;
  background-color: #818181;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}

/* 滚动条的轨道部分 */
::v-deep .el-scrollbar__wrap {
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 轨道的背景颜色 */
  }
}

/* 修改滑块宽度 */
::v-deep .el-scrollbar__bar.is-horizontal {
  height: 12px;
}

::v-deep .el-scrollbar__bar.is-vertical {
  width: 12px;
}
</style>
