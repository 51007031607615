<template>
  <el-dialog v-model="isShowList" width="70%" :destroy-on-close="true">
    <template #header> 商户名称 : {{ merchantInfo.merchant_name }} </template>
    <div class="table-head">
      <span class="table-title">商户门店列表</span>
      <span class="table-btn">
        <el-button @click="addStore" type="primary">
          <el-icon style="color: #fff">
            <Plus />
          </el-icon>
          <span style="margin-left: 15px">添加门店</span>
        </el-button>
      </span>
    </div>
    <div class="table-main">
      <el-table :data="storesData" style="width: 100%" v-loading="storesloading">
        <el-table-column label="序号" width="80" type="index" align="center">
          <template #default="scope">
            {{ (storePage.currentPage - 1) * storePage.perPage + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="门店名称" />
        <el-table-column prop="address" label="门店地址" />
        <el-table-column prop="contact_name" label="联系人" width="100px" />
        <el-table-column prop="phone" label="联系电话" width="180px" />
        <el-table-column prop="devices_count" label="已绑定设备数量" width="160px" />
        <el-table-column label="操作" width="220px" align="center">
          <template #default="scope">
            <el-link style="margin-left:15px" type="primary" @click="handleEdit(scope.row)">编辑</el-link>
            <el-link style="margin-left:15px" type="primary" @click="handlePlugin(scope.row)">收银插件开通</el-link>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :total="storePage.total"
          @current-change="storesPageChange" :page-size="storePage.perPage" :current-page="storePage.currentPage" />
      </div>
    </div>

    <!-- edit/add store dialog -->
    <el-dialog v-model="isShowEditStore" width="40%" :destroy-on-close="true">
      <template #header>
        {{ isEditMode ? '编辑门店' : '添加门店' }}
      </template>
      <el-form ref="editFormRef" :model="editData" :rules="rules" label-width="150px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="门店名称" prop="name">
              <el-input v-model="editData.name" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="门店地址" prop="address">
              <el-input v-model="editData.address" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="联系人" prop="contact_name">
              <el-input v-model="editData.contact_name" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="editData.phone" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="editData.status">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button @click="isShowEditStore = false">取消</el-button>
        <el-button type="primary" @click="handleEditSubmit">{{ isEditMode ? '确定' : '添加' }}</el-button>
      </template>
    </el-dialog>
    <!-- edit/add store dialog end -->
  </el-dialog>
</template>

<script setup>
import { ref, defineExpose, getCurrentInstance, reactive } from 'vue'
import { nextTick } from 'process';
import { ElMessage } from 'element-plus'
import { Plus } from "@element-plus/icons-vue";
import { phone } from '@/tools/validatorFunc'
import { useRouter } from "vue-router"

const route = useRouter()
const { proxy } = getCurrentInstance() // 当前实例
const merchantInfo = ref('')
const isShowList = ref(false)

// LIST
const storesloading = ref(true)
let storesData = ref()
let storePage = reactive({
  currentPage: 0,
  perPage: 0,
  total: 0,
  totalPages: 0
})

const storesPageChange = (page) => {
  queryStoresList({ page: page })
}

const handleEdit = (row) => {
  isEditMode.value = true
  nextTick(() => {
    isShowEditStore.value = true
    editData.value = { ...row }
  })
}

const handlePlugin = (row) => {
  route.push({
    path: '/users/plugin',
    query: {
      id: row.id,
      name: row.name
    }
  })
}
// LIST END

// EDIT/ADD
const isShowEditStore = ref(false)
const isEditMode = ref(false)
const editData = ref({ name: '', address: '', contact_name: '', phone: '', status: 1 })

const rules = reactive({
  name: [
    { required: true, message: '必填项' }
  ],
  address: [
    { required: true, message: '必填项' }
  ],
  contact_name: [
    { required: true, message: '必填项' }
  ],
  phone: [
    { required: true, message: '必填项' },
    { validator: phone, trigger: 'change' }
  ],
  status: [
    { required: true, message: '必填项' }
  ]
})

const handleEditSubmit = () => {
  proxy.$refs.editFormRef.validate((validate) => {
    if (validate) {
      const apiCall = isEditMode.value
        ? proxy.$api.merchants.editStores(editData.value.id, editData.value)
        : proxy.$api.merchants.addStore(editData.value)
      apiCall.then(res => {
        if (res.status == 200) {
          ElMessage({
            message: isEditMode.value ? '数据修改成功' : '门店添加成功',
            type: 'success',
          })
          isShowEditStore.value = false
          queryStoresList({ merchant_id: merchantInfo.value.id })
        } else {
          ElMessage.error('操作失败，请稍后重试')
          isShowEditStore.value = false
        }
      })
    }
  })
}

const addStore = () => {
  isEditMode.value = false
  editData.value = { name: '', address: '', contact_name: '', phone: '', status: 1, merchant_id: merchantInfo.value.id, agent_id: merchantInfo.value.agent_id }
  isShowEditStore.value = true
}

const queryStoresList = (data) => {
  storesloading.value = true
  proxy.$api.merchants.queryStores(data).then(res => {
    if (res.status == 200) {
      storesData.value = res.data.data
      storePage.currentPage = res.data.pagination.currentPage
      storePage.perPage = res.data.pagination.perPage
      storePage.total = res.data.pagination.total
      storePage.totalPages = res.data.pagination.totalPages
      storesloading.value = false
    } else {
      ElMessage.error('加载失败')
    }
  })
}

const open = (item) => {
  isShowList.value = true
  merchantInfo.value = item
  nextTick(() => {
    queryStoresList({ merchant_id: item.id })
  })
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.table-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.table-main {
  margin: 1.25rem 0;

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
