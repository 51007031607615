<script setup>
import { reactive, getCurrentInstance, onMounted, ref } from "vue-demi";
import { GetCurrentMonthTime, GetYesterdayEndTime } from "../../tools/time"
const { appContext } = getCurrentInstance()

const searchInfo = reactive({
  time: [GetCurrentMonthTime(), GetYesterdayEndTime()]
})

const hanldeSerchBtn = () => {
  console.log(searchInfo.time[0],searchInfo.time[1]);
  queryData({ agent_id: searchInfo.agent_id, created_start: searchInfo.time[0], created_end: searchInfo.time[1] })
}

const pageChange = (page) => {
  console.log(page);
}

/* 下载事件 */
const exportRow = (row) => {
  searchInfo['agent_id'] = row.agent_id
  if (searchInfo.time.length >= 2) {
    searchInfo['time_start'] = searchInfo.time[0]
    searchInfo['time_end'] = searchInfo.time[1]
  }

  exportExcel(searchInfo)
}

const loading = ref(true)

// 表格数据
const queryData = (data) => {
  appContext.config.globalProperties.$api.agentProfit.query(data).then(r => {
    if (r.status == 200) {
      viewData.tableData = r.data.data
      viewData.pageinfo = r.data.pagination
    }
  })
  queryCensus(data)
}

// 汇总数据
const queryCensus = (data) => {
  appContext.config.globalProperties.$api.agentProfit.queryCensus(data).then(r => {
    if (r.status == 200) {
      viewData.panel.number = r.data.pay_number;
      viewData.panel.transaction_amount = r.data.total_amount > 0 ? r.data.total_amount : 0;
      viewData.panel.all_discount_amount = 0;
      viewData.panel.all_amount = r.data.pay_amount > 0 ? r.data.pay_amount : 0;
      viewData.panel.all_fee_amount = r.data.fee_amount;
    }
  })
  loading.value = false
}

onMounted(() => {
  queryAgents()
})

const agentOption = ref()
const queryAgents = () => {
  appContext.config.globalProperties.$api.agentsList({ pageSize: "999" }).then(res => {
    if (res.status == 200) {
      agentOption.value = res.data
    }
  })

  if (searchInfo.time.length >= 2) {
    searchInfo['time_start'] = searchInfo.time[0]
    searchInfo['time_end'] = searchInfo.time[1]
  }
  queryData(searchInfo)
}

// 下载
const exportExcel = (d) => {
  const filename = '代理商分润统计';
  appContext.config.globalProperties.$api.agentProfit.exportData(d, filename)
}

const viewData = reactive({
  panel: {
    number: 0,
    transaction_amount: 0,
    all_discount_amount: 0,
    all_amount: 0,
    all_fee_amount: 0
  },
  pageinfo: {
    total: 0,
    perPage: 15,
    currentPage: 0
  },
  tableData: []
})

</script>


<template>
  <div class="container">
    <div class="topbar">
      <el-form :inline="true" :model="searchInfo">
        <el-form-item label="代理商名称">
          <el-select v-model="searchInfo.agent_id" placeholder="请输入/选择代理商名称" filterable>
            <el-option v-for="item in agentOption" :key="item.id" :label="item.company" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="起始时间">
          <el-date-picker v-model="searchInfo.time" type="datetimerange" range-separator="至" start-placeholder="起始时间"
            end-placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss" />
        </el-form-item>
      </el-form>
      <el-button @click="hanldeSerchBtn()" type="primary">搜索</el-button>
    </div>

    <div class="mian">
      <div class="desc">
        <p>总交易笔数<span>{{ viewData.panel.number }}</span>笔</p>
        <p>总交易金额<span>{{ viewData.panel.transaction_amount }}</span>元</p>
        <p>总手续费<span>{{ viewData.panel.all_fee_amount }}</span>元</p>
        <p>总支付金额<span>{{ viewData.panel.all_amount }}</span>元</p>
        <p>总优惠金额<span>{{ viewData.panel.all_discount_amount }}</span>元</p>
      </div>
      <el-table :data="viewData.tableData" style="width: 100%" v-loading="loading">
        <el-table-column type="index" width="50" />
        <el-table-column prop="agent_name" label="代理商名称" />
        <el-table-column prop="total_amount" label="总交易金额" />
        <el-table-column prop="pay_number" label="交易笔数" />
        <el-table-column prop="pay_amount" label="总支付金额" />
        <el-table-column prop="fee_amount" label="总手续费" />
        <el-table-column prop="receivable_profit" label="应收下级分润" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button link type="primary" size="small" @click.prevent="exportRow(scope.row)">
              导出
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :total=viewData.pageinfo.total
          @current-change="pageChange" />
      </div>
    </div>
  </div>
</template>


<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .topbar {
    display: flex;
  }

  .mian {
    padding-top: 14px;

    .desc {
      width: 100%;
      padding: 8px 0;
      background: #f0f0ff;
      display: flex;
      font-size: 14px;
      user-select: none;

      p {
        text-align: center;
        width: 20%;
        border-right: 2px solid #fff;

        span {
          margin: 0 8px;
          font-size: 18px;
          color: #ff4757;
        }
      }

      p:last-child {
        border-right: none;
      }
    }

    .pagination {
      margin: 1.25rem 0;
      display: flex;
      justify-content: flex-end;

      .demonstration {
        margin-bottom: 16px;
      }
    }
  }
}
</style>