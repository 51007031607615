<template>
    <div>
        <el-card style="max-width: 100%">
            <div style="margin-top: 20px;">
                <el-form :model="form" label-width="120px" class="responsive-form" label-position="right">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                          <el-form-item label="一级代理:">
                            <el-select v-model="form.agent_id" filterable clearable>
                              <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                            <el-checkbox style="margin-left: 8px;" v-model="form.include_children" true-label=1 false-label=0 size="small">包含下级</el-checkbox>
                          </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="业务员:">
                                <el-select v-model="form.salesman_id" filterable clearable placeholder="请选择">
                                    <el-option v-for="item in salemanList" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="商户:">
                                <el-select v-model="form.merchant_id" filterable clearable placeholder="请选择">
                                    <el-option v-for="item in merchantList" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12" :lg="6">
                            <el-form-item label="月份:">
                                <el-date-picker v-model="form.month" type="month" placeholder="请选择月份"
                                    value-format="YYYYMM" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="display: flex; justify-content: center;margin-bottom: 20px;">
                <el-button type="primary" @click="handleSearch">查询</el-button>
            </div>
        </el-card>
    </div>
    <div style="margin-top: 20px;">
        <el-card>
            <template #header>
                <div class="card-header" style="display: flex;justify-content: space-between;">
                    <span>商户月报表</span>
                    <span class="export-excel" @click="hanldeExportExcel">
                        <el-icon>
                            <DocumentDelete />
                        </el-icon>
                        导出excel
                    </span>
                </div>
            </template>
            <div class="table-main">
                <el-table :data="tableData" v-loading="isloading" :border="true" stripe style="width: 100%">
                    <el-table-column label="月份" prop="month" />
                    <el-table-column label="一级代理商" prop="first_agent_name" width="180" show-overflow-tooltip />
                    <el-table-column label="二级代理商" prop="second_agent_name" width="120" show-overflow-tooltip />
                    <el-table-column label="三级代理商" prop="third_agent_name" width="120" show-overflow-tooltip />
                    <el-table-column label="业务员" prop="salesman_name" />
                    <el-table-column label="商户编号" prop="merchant_no" width="120" show-overflow-tooltip />
                    <el-table-column label="商户名称" prop="merchant_alias" show-overflow-tooltip width="160" />
                    <el-table-column label="支付通道" prop="payment_channel" />
                    <el-table-column label="交易笔数" prop="total_count" width="120" show-overflow-tooltip />
                    <el-table-column label="费率" prop="rate" />
                    <el-table-column label="交易金额" prop="total_amount" width="120" show-overflow-tooltip />
                </el-table>
            </div>
            <div class="pagination" style="display: flex;justify-content: flex-end;">
                <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
                    @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
                    :hide-on-single-page="false">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue';
import dayjs from 'dayjs';
import { DocumentDelete } from '@element-plus/icons-vue';
const { proxy } = getCurrentInstance();

const agentList = ref([]);
const merchantList = ref([]);
const salemanList = ref([]);
const form = ref({});
const tableData = ref([]);
let isloading = ref(false);
let pageinfo = ref({
    perPage: 0, // 每页多少条
    total: 0, // 共计多少
    totalPages: 0, // 共计多少页
    currentPage: 1, // 当前页数
});

const pageChange = (page) => {
    hasSearch(page);
};

const hasSearch = (page) => {
    isloading.value = true;
    let info = { ...form.value, page: page };
    queryMerchantMonthlyList(info);
};

const handleSearch = () => {
    isloading.value = true;
    pageinfo.value.currentPage = 1; // Reset to the first page
    let info = { ...form.value, page: 1 };
    queryMerchantMonthlyList(info);
};

// 查询代理商列表
const queryAgentList = async () => {
    const response = await proxy.$api.queryAgentList();
    agentList.value = transformData(response.data.data.tree);
};

// 查询业务员列表
const querySalesmenList = async () => {
    const response = await proxy.$api.querySalesmenList();
    salemanList.value = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
    }));
};

// 查询商户列表
const queryMerchantList = async () => {
    const response = await proxy.$api.queryMerchantList();
    merchantList.value = response.data.data.map((item) => ({
        value: item.id,
        label: item.no + '-' + item.merchant_alias,
    }));
};

// 商户每月报表
const queryMerchantMonthlyList = async (data) => {
    const response = await proxy.$api.agents.queryMerchantMonthlyList(data);
    tableData.value = response.data.data;
    pageinfo.value = {
        perPage: response.data.pagination.perPage,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.totalPages,
        currentPage: response.data.pagination.currentPage
    };
    isloading.value = false;
};

// 转换数据格式
const transformData = (nodes) => {
    return nodes.map((node) => ({
        value: node.id,
      label: node.company + ' ('+ node.name + ')',
    }));
};

//导出
const hanldeExportExcel = async () => {
    let info = { ...form.value };
  info.include_children = 1
  const selectedMonth  = form.value.month;
  const agentName = agentList.value.find(item => item.value === form.value.agent_id)?.label || '全部';
  const fileName = `${selectedMonth}_${agentName}_代理商户月报表`;
  await proxy.$api.agents.exportMerchantCommission(info, fileName)
}

// 页面挂载时执行
onMounted(() => {
    form.value.month = dayjs().subtract(1, 'month').format('YYYYMM');
    queryAgentList();
    handleSearch();
    queryMerchantList();
    querySalesmenList();
});
</script>

<style scoped>
.export-excel {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #409eff;
    cursor: pointer;
    -webkit-user-select: none;
}

.export-excel:hover {
    color: #40ff59;
}
</style>
