<script setup>
import { ref, reactive, getCurrentInstance, defineExpose } from "vue";
import { ElMessage, ElNotification } from 'element-plus'
// import Photo from "../../../components/CustomImage"
import Upload from '@/components/CustomUpload'
import { ArrowLeft } from '@element-plus/icons-vue'
import { regionData } from 'element-china-area-data'
import { formatDate } from '@/tools/time'
const { appContext } = getCurrentInstance()

const step = ref(0)
const merchantDetail = ref({
    id: '',
    salesman: {
        id: '',
    },
    agent: {
        id: ''
    }
})
const rules = reactive({
    merchant_name: [
        {
            required: true,
        }
    ],
    business_type: [
        {
            required: true,
        }
    ],
    legal_person_holding_id_card_url: [
        {
            required: true,
        }
    ],
    legal_person_id_card_front_url: [
        {
            required: true,
        }
    ],
    legal_person_id_card_reverse_url: [
        {
            required: true,
        }
    ],
    legal_person_name: [
        {
            required: true
        }
    ],
    legal_person_id_card_no: [
        {
            required: true
        }
    ],
    legal_person_id_card_effect_date: [
        {
            required: true
        }
    ],
    license_pic_url: [
        {
            required: true
        }
    ],
    license_effect_date: [
        {
            required: true
        }
    ],
    merchant_company: [
        {
            required: true
        }
    ],
    license_no: [
        {
            required: true
        }
    ],
    merchant_alias: [
        {
            required: true
        }
    ],
    mcc_code: [
        {
            required: true
        }
    ],
    first_mcc_code: [
        {
            required: true
        }
    ],
    province_code: [
        {
            required: true
        }
    ],
    business_address: [
        {
            required: true
        }
    ],
    business_scope: [
        {
            required: true
        }
    ],
    contact_person_name: [
        {
            required: true
        }
    ],
    contact_person_phone: [
        {
            required: true
        }
    ],
    store_indoor_url: [
        {
            required: true
        }
    ],
    store_header_url: [
        {
            required: true
        }
    ],
    store_cashier_url: [
        {
            required: true
        }
    ],
    settlement_card_front_url: [
        {
            required: true
        }
    ],
    settlement_card_reverse_url: [
        {
            required: true
        }
    ],
    settlement_account_no: [
        {
            required: true
        }
    ],
    settlement_account_bank_code: [
        {
            required: true
        }
    ],
    settlement_account_phone: [
        {
            required: true
        }
    ],
    settlement_account_bank_city_code: [
        {
            required: true
        }
    ],
    settlement_account_bank_branch_no: [
        {
            required: true
        }
    ],
    account_type: [
        {
            required: true
        }
    ],
    settlement_type: [
        {
            required: true
        }
    ],
    settlement_person_id_card_front: [
        { required: true },
    ],
    settlement_person_id_card_reverse: [
        { required: true },
    ],
    authorization_letter: [
        { required: true },
    ],
    settlement_person_id_card_no: [
        { required: true },
    ],
    settlement_account_name: [
        { required: true },
    ],
    permit_for_opening_bank_account: [
        { required: true }
    ],
})
const options = [
    {
        value: 1,
        label: "个体工商户",
    },
    {
        value: 2,
        label: '有限责任公司',
    },
    {
        value: 3,
        label: '非公司企业法人',
    },
    {
        value: 4,
        label: '私营合伙企业',
    },
    {
        value: 5,
        label: '私营独资企业',
    },
    {
        value: 6,
        label: '股份有限责任公司',
    }
]

const mccCode = reactive([])
const banklist = reactive([])
const loading = ref(true)

const preStep = () => {
    step.value -= 1
}

const nextStep = () => {
    step.value += 1
    if (step.value >= 3) {
        step.value = 3
    }
}

let region = ref()
region.value = regionData

const handlebanck = () => {
    isShowDetaill.value = false
    step.value = 0
    merchantDetail.value = {}
    merchantDetail.value = {
        id: null,
        salesman: {
            id: null
        },
        agent: {
            id: null
        },
        legal_person_id_card_front_url: null,
        legal_person_id_card_reverse_url: null,
        license_pic_url: null,
        legal_person_holding_id_card_url: null
    }
}


// 地址处理
let addressList = ref('')
addressList.value = regionData
// 经营地
let premises = ref([])  // 值

const DataProcessing = (data) => {
    let arr = []
    arr.push(JSON.stringify(data.province_code))
    arr.push(JSON.stringify(data.city_code))
    arr.push(JSON.stringify(data.district_code))
    premises.value = arr

    DataWhereItIS(data)
}

// 开户行
let whereItIs = ref([])
const DataWhereItIS = (data) => {
    let arr = []
    arr.push(data.settlement_account_bank_province_code)
    arr.push(data.settlement_account_bank_city_code)
    arr.push(data.settlement_account_bank_district_code)

    whereItIs.value = arr
    const branch = {
        bank_id: merchantDetail.value.settlement_account_bank_code,
        province_code: data.settlement_account_bank_province_code,
        city_code: data.settlement_account_bank_city_code
    }
    quertBankList(branch)
}

// // 查询开户支行
const branchBanksOptions = ref(null)
// // 查询开户支行地址
const quertBankList = (data) => {
    appContext.config.globalProperties.$api.banks.branchBanks(data).then(res => {
        if (res.status == 200) {
            branchBanksOptions.value = res.data.data
        }
    })
}

// 查询商户信息
const queryMerchantInfo = (id) => {
    loading.value = true
    appContext.config.globalProperties.$api.merchants.queryinfo(id).then(res => {
        if (res.status == 200) {
            console.log(res.data.data, '商户信息');

            DataProcessing(res.data.data)
            const response = res.data.data
            response.legal_person_id_card_effect_date = formattedDate(response.legal_person_id_card_effect_date)
            response.legal_person_id_card_expiry_date = formattedDate(response.legal_person_id_card_expiry_date)
            response.license_effect_date = formattedDate(response.license_effect_date)
            response.license_expiry_date = formattedDate(response.license_expiry_date)
            response.settlement_person_id_card_effect_date = formattedDate(response.settlement_person_id_card_effect_date)
            response.settlement_person_id_card_expiry_date = formattedDate(response.settlement_person_id_card_expiry_date)
            merchantDetail.value = { ...response }
            // merchantDetail.value = {...res.data.data}
            if (res.data.data.first_mcc_code != null) {
                merchantDetail.value.first_mcc_code = res.data.data.first_mcc_code.toString()
                getGtxyTopMcc(res.data.data.business_type)
            } else {
                getGtxyTopMcc(res.data.data.business_type)
            }

        } else {
            ElMessage.error("数据加载失败,请稍后重试！")
        }
    })
    loading.value = false
    queryMCC()
}

// 查询商户类别
const queryMCC = () => {
    appContext.config.globalProperties.$api.mcc.list().then(res => {
        if (res.status == 200) {
            res.data.data.forEach(item => {
                if (item.children.length > 0) {
                    let new_ary = { ...item }
                    new_ary["value"] = item.code
                    new_ary["label"] = item.name
                    new_ary["children"] = item.children.map((citem) => {
                        citem["value"] = citem.code
                        citem["label"] = citem.name
                        return citem
                    })
                    mccCode.push(new_ary)
                }
            })
        } else {
            ElMessage.error("商户类别查询失败！")
        }
    })
    queryBank()

}

// 查询一级行业码
const topMcc = ref([])
const getGtxyTopMcc = (e) => {
    let merchant_type = '';
    switch (e) {
        case '0':
            merchant_type = '2';
            break;
        case '1':
            merchant_type = '1';
            break;
        case '2':
            merchant_type = '4';
            break;
        default:
            return;
    }

    appContext.config.globalProperties.$api.mcc.getGtxyTopMcc({ merchant_type: merchant_type }).then(res => {
        if (res.status == 200) {
            topMcc.value = res.data.data.data
            getGtxySecondMcc(merchantDetail.value.first_mcc_code)
        }
    })

}


// 查询二级行业码
const mccCodeList = ref([])
const getGtxySecondMcc = (e) => {
    appContext.config.globalProperties.$api.mcc.getGtxySecondMcc({ first_mcc_id: e }).then(res => {
        if (res.status == 200) {
            mccCodeList.value = res.data.data.data
        }
    })

}

// 选择一级行业码
const selectTopMcc = (e) => {
    merchantDetail.value.mcc_code = ''
    getGtxySecondMcc(e)
}


// 查询银行
const queryBank = () => {
    appContext.config.globalProperties.$api.banks.list().then(res => {
        if (res.status == 200) {
            banklist.length != 0 ? banklist.length = 0 : ''
            res.data.data.forEach(item => {
                item.label = item.name
                banklist.push(item)
            })
        } else {
            ElMessage.error("银行类别查询失败！")
        }
    })
}

const isShowDetaill = ref(false)

const open = (item) => {
    isShowDetaill.value = true
    step.value = 0
    queryMerchantInfo(item.id)
    queryAgentsList()
}

defineExpose({ open })


const inputstyle = "width:100%"
const timestyle = "width:140px"

// 查询代理商
const agentsList = ref()
const queryAgentsList = () => {
    appContext.config.globalProperties.$api.agentsList({ pageSize: 999 }).then(res => {
        if (res.status == 200) {
            agentsList.value = res.data
        }
    })
    querySalesmanList()
}
const handleSelectAgents = (id) => {
    querySalesmanList(id)
}
// 查询业务员
const salesmanList = ref()
const querySalesmanList = (salesmanid) => {
    appContext.config.globalProperties.$api.salesman.list({ pageSize: 999, agent_id: salesmanid }).then(res => {
        if (res.status == 200) {
            salesmanList.value = res.data.data
        }
    })
    queryBank()
}

// 企业类型
const handleBusinessType = (val) => {
    if (val == 1) {
        merchantDetail.value.company_type = 5
    } else {
        delete merchantDetail.value.company_type
    }
    getGtxyTopMcc(val.toString())

}

function bankAddress(val) {

    merchantDetail.value.settlement_account_bank_province_code = val[0]
    merchantDetail.value.settlement_account_bank_city_code = val[1]
    merchantDetail.value.settlement_account_bank_district_code = val[2]
    const data = {
        province_code: val[0],
        city_code: val[1],
        district_code: val[2],
        bank_id: merchantDetail.value.settlement_account_bank_code
    }
    quertBankList(data)
}

// 处理mccode格式
// function hanldeMccCode(val) {
//   merchantDetail.value.mcc_code = val[1]
// }

// 处理经营地格式
function hanldePremises(val) {
    merchantDetail.value.province_code = val[0]
    merchantDetail.value.city_code = val[1]
    merchantDetail.value.district_code = val[2]
}

const fullscreenLoading = ref(false)

// 修改
const hanldeSeveData = () => {
    fullscreenLoading.value = true
    editDetail(merchantDetail.value)
}

function editDetail(data) {
    console.log(data, '修改前');
    appContext.config.globalProperties.$api.merchants.edit(data.id, data).then(r => {
        if (r.status == 200) {
            ElNotification({ title: '操作成功', message: '商户信息修改成功', type: 'success' })
            handlebanck()
        } else if (r.status == 422) {
            ElNotification({ title: '格式错误', message: r.error.fields, type: 'warning' })
        } else {
            ElNotification({ title: '服务器内部错误', message: r.message, type: 'error' })
        }
    }).catch(err => {
        ElNotification({ title: '服务器内部错误', message: err.response.data.message, type: 'error' })
    })
    fullscreenLoading.value = false
}


// 手持身份证回调
function uploadLegalPersonHoldingIdCard(id, url) {
    merchantDetail.value.legal_person_holding_id_card_url = url
    merchantDetail.value.legal_person_holding_id_card = id
}

// 营业执照回调
function uploadLicensePic(id, url, ocr) {

    const licenseNameWithChineseBrackets = ocr.license_name.replace(/\(/g, '（').replace(/\)/g, '）');
    merchantDetail.value.license_pic = id
    merchantDetail.value.merchant_name = licenseNameWithChineseBrackets
    merchantDetail.value.license_effect_date = formatDate(ocr.register_date)
    merchantDetail.value.license_expiry_date = formatDate(ocr.expire_date)
    merchantDetail.value.merchant_company = licenseNameWithChineseBrackets
    merchantDetail.value.license_no = ocr.license_no
    merchantDetail.value.business_address = ocr.license_address
    merchantDetail.value.license_pic_url = ocr.url
    /* 模糊匹配 是否永久*/
    const never = /99{2,4}/g
    if (never.test(ocr.expire_date)) {
        merchantDetail.value.license_expiry_forever = 1
    } else {
        merchantDetail.value.license_expiry_forever = 0
    }
    /* 模糊匹配 企业类型*/
    const name = ocr.license_address.slice(0, 4)
    switch (name) {
        case "个体工商":
            merchantDetail.value.company_type = 1
            break;
        case "有限责任":
            merchantDetail.value.company_type = 2
            break;
        case "非公司企":
            merchantDetail.value.company_type = 3
            break;
        case "私营合伙":
            merchantDetail.value.company_type = 4
            break;
        case "私营独资":
            merchantDetail.value.company_type = 5
            break;
        case "股份有限":
            merchantDetail.value.company_type = 6
            break;
    }
}

// 法人身份证人面像
function uploadidCardFront(id, url, ocr) {
    merchantDetail.value.legal_person_id_card_front = id
    merchantDetail.value.legal_person_id_card_front_url = url
    merchantDetail.value.legal_person_name = ocr.name
    merchantDetail.value.legal_person_id_card_no = ocr.id_card_no
}
// 法人身份证国徽面
function uploadidCardReverse(id, url, ocr) {
    merchantDetail.value.legal_person_id_card_reverse_url = url
    merchantDetail.value.legal_person_id_card_reverse = id
    merchantDetail.value.legal_person_id_card_effect_date = formatDate(ocr.is_use_date)
    const never = /99{2,4}/g
    if (never.test(ocr.expire_date)) {
        merchantDetail.value.legal_person_id_card_expiry_forever = 1
        merchantDetail.value.legal_person_id_card_expiry_date = formatDate(ocr.expire_date)
    } else {
        merchantDetail.value.legal_person_id_card_expiry_forever = 0
        merchantDetail.value.legal_person_id_card_expiry_date = formatDate(ocr.expire_date)
    }
}

// 联系人人面相
// function uploadContactPersonIdCardFront(id,url,ocr){
//   merchantDetail.value.contact_person_id_card_front=id
//   merchantDetail.value.contact_person_id_card_no=ocr.id_card_no
//   merchantDetail.value.contact_person_name=ocr.name
//   merchantDetail.value.legal_person_id_card_front_url=url
// }

// 店内照片
function uploadStoreIndoor(id, url) {
    merchantDetail.value.store_indoor = id
    merchantDetail.value.store_indoor_url = url
}

// 门头照片
function uploadStoreHeader(id, url) {
    // console.log(id);
    merchantDetail.value.store_header = id
    merchantDetail.value.store_header_url = url
}
// 经营场所
function uploadStoreCashier(id, url) {
    merchantDetail.value.store_cashier = id
    merchantDetail.value.store_cashier_url = url
}

// 银行卡正面
const backCard = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
function uploadSettlementCardFront(id, url, ocr) {
    merchantDetail.value.settlement_card_front_url = url
    merchantDetail.value.settlement_card_front = id
    if (ocr.bank_card_number != '' && backCard.test(ocr.bank_card_number)) {
        merchantDetail.value.settlement_account_no = ocr.bank_card_number
    }
}

// 银行卡反面
function uploadSettlementCardReverse(id, url, ocr) {
    merchantDetail.value.settlement_card_reverse_url = url
    merchantDetail.value.settlement_card_reverse = id
    if (ocr.bank_card_number != '' && backCard.test(ocr.bank_card_number)) {
        merchantDetail.value.settlement_account_no = ocr.bank_card_number
    }
}
// 结算人身份证正面
function uploadSettlementPersonIdCardFront(id, url, ocr) {
    merchantDetail.value.settlement_person_id_card_front = id,
        merchantDetail.value.settlement_person_id_card_front_url = url
    merchantDetail.value.settlement_person_id_card_no = ocr.id_card_no
    merchantDetail.value.settlement_account_name = ocr.name
}

// 结算人身份证反面
function uploadSettlementPersonIdCardReverse(id, url, ocr) {
    // merchantDetail.value.settlement_person_id_card_reverse = id
    // merchantDetail.value.settlement_person_id_card_reverse_url = url
    // merchantDetail.value.settlement_person_id_card_effect_date = ocr.is_use_date

    // const never = /99{2,4}/g

    // if (never.test(ocr.expire_date)) {
    //   merchantDetail.value.settlement_person_id_card_expiry_forever = 1
    //   merchantDetail.value.settlement_person_id_card_expiry_date = ocr.expire_date
    // } else {
    //   merchantDetail.value.settlement_person_id_card_expiry_forever = 0
    //   merchantDetail.value.settlement_person_id_card_expiry_date = ocr.expire_date
    // }

    merchantDetail.value.settlement_person_id_card_reverse = id
    merchantDetail.value.settlement_person_id_card_effect_date = formatDate(ocr.is_use_date)
    const never = /99{2,4}/g

    if (never.test(ocr.expire_date)) {
        merchantDetail.value.settlement_person_id_card_expiry_forever = 1
        merchantDetail.value.settlement_person_id_card_expiry_date = formatDate(ocr.expire_date)
    } else {
        merchantDetail.value.settlement_person_id_card_expiry_forever = 0
        merchantDetail.value.settlement_person_id_card_expiry_date = formatDate(ocr.expire_date)
    }
}

// 开户许可证
function uploadPermitOpeningBankAccount(id, url) {
    merchantDetail.value.permit_for_opening_bank_account = id
    merchantDetail.value.permit_for_opening_bank_account_url = url
}

// // 非法人结算授权函
function uploadAuthorizationLetter(id, url) {
    merchantDetail.value.authorization_letter = id
    merchantDetail.value.authorization_letter_url = url
}

// // 收款人手持身份证收款卡照
function uploadHoldIdCardBankCard(id, url) {
    merchantDetail.value.hold_id_card_bank_card = id
    merchantDetail.value.hold_id_card_bank_card_url = url
}

// // 法人与收款人授权书合照(邮政必填)
function uploadHoldAuthorizationLetter(id, url) {
    merchantDetail.value.hold_authorization_letter = id
    merchantDetail.value.hold_authorization_letter_url = url
}

const formattedDate = (dateinit, format = 'yyyy-mm-dd') => {
    let format_str = format
    if (dateinit === null || dateinit === 0 || dateinit === '' || dateinit === undefined) {
        return " "
    }
    let date = new Date(dateinit)
    let date_str = {
        "y+": date.getFullYear(),
        'm+': date.getMonth() + 1,
        'd+': date.getDate(),
    }
    for (let item in date_str) {
        if (new RegExp('(' + item + ')', 'i').test(format_str)) {
            format_str = format_str.replace(
                RegExp.$1,
                date_str[item].toString().length < 2 ? '0' + date_str[item] : date_str[item]
            )
        }
    }
    return format_str
}

//法人证件有效期是否永久
function changeLegalPersonIdCardExpiryForever(val) {
    merchantDetail.value.legal_person_id_card_expiry_forever = val
}

// 执照有效期是否永久
function changeLicenseExpiryForever(val) {
    merchantDetail.value.license_expiry_forever = val
}
</script>
<template>
    <!-- <el-dialog v-model="isShowDetaill" width="80%" :destroy-on-close="false" :show-close=false :fullscreen=true> -->
    <el-dialog v-model="isShowDetaill" width="70%" :destroy-on-close="false" :show-close=false :fullscreen=true>
        <el-skeleton :loading="loading" animated :rows="20" :throttle=2>
            <div class="container">
                <el-page-header :icon="ArrowLeft" @back="handlebanck">
                    <template #content>
                        <span> 商户详情 </span>
                    </template>
                </el-page-header>

                <div class="tab">
                    <el-steps :active="step" align-center>
                        <el-step title="资质信息" />
                        <el-step title="基础信息" />
                        <el-step title="结算信息" />
                    </el-steps>
                </div>

                <div class="main">
                    <el-form :model="merchantDetail" label-width="180px" label-position="right" :scroll-to-error="true"
                        require-asterisk-position="left" :rules="rules" :inline-message="true" size="large">

                        <div style="display: flex; align-items: center;justify-content: center; padding: 20px;"
                            v-if="step == 0">
                            <el-result title="查看商户资料" sub-title="请勿乱改动商户数据，部分数据无法显示，请刷新后重试">
                            </el-result>
                        </div>

                        <div v-if="step == 1">
                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="代理商:">
                                        <el-select v-model="merchantDetail.agent.id" placeholder="请选择代理商" clearable
                                            @change="handleSelectAgents" :style=inputstyle>
                                            <el-option v-for="item in agentsList" :key="item.id"
                                                :label="item.name + '-' + item.phone" :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2"></el-col>
                                <el-col :span="10">
                                    <el-form-item label="业务员:">
                                        <el-select v-model="merchantDetail.salesman.id" placeholder="请选择业务员" clearable
                                            :style=inputstyle>
                                            <el-option v-for="item in salesmanList" :key="item.id"
                                                :label="item.name + '-' + item.phone" :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="商户名称:" prop="merchant_name">
                                        <el-input v-model="merchantDetail.merchant_name" placeholder="与营业执照一致,或个体户xxx"
                                            clearable />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2"></el-col>
                                <el-col :span="10">
                                    <el-form-item label="商户类型:" prop="business_type">
                                        <el-radio-group v-model="merchantDetail.business_type"
                                            @change="handleBusinessType">
                                            <el-radio-button :label=0>企业</el-radio-button>
                                            <el-radio-button :label=1>个体工商</el-radio-button>
                                            <el-radio-button :label=2>小微商户</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <!-- 小微商户 -->
                            <div v-if="merchantDetail.business_type == 2">
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="手持身份证:" prop="legal_person_holding_id_card_url">
                                            <Upload :img="merchantDetail.legal_person_holding_id_card_url"
                                                @success="uploadLegalPersonHoldingIdCard" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <!-- 企业，个体工商户  -->
                            <div v-else>
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="营业执照:" prop="license_pic_url">
                                            <Upload :img="merchantDetail.license_pic_url" @success="uploadLicensePic"
                                                type="LICENSE" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="公司类型:">
                                            <el-select v-model="merchantDetail.company_type" clearable>
                                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                    :value="item.value" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2"></el-col>
                                    <el-col :span="8">
                                        <!-- <p style="opacity: 0;">{{ merchantDetail.license_effect_date }} - {{ merchantDetail.license_expiry_date }}</p> -->
                                        <el-form-item label="执照有效期:" prop="license_effect_date">
                                            <div style="display: flex; justify-content: space-between;">
                                                <el-date-picker v-model="merchantDetail.license_effect_date" type="date"
                                                    placeholder="执照生效时间" value-format="YYYY-MM-DD" :style=timestyle />
                                                <span style="margin:0 10px">至</span>
                                                <el-date-picker v-model="merchantDetail.license_expiry_date" type="date"
                                                    :disabled="merchantDetail.license_expiry_forever == 1 ? true : false"
                                                    placeholder="执照失效时间" value-format="YYYY-MM-DD" :style=timestyle />
                                                <!-- <el-checkbox v-model="merchantDetail.license_expiry_forever" checked="{{ merchantDetail.license_expiry_forever ==1 ?true: false }}" true-label="1" false-label="0" style="margin-left: 16px;">是否长期</el-checkbox> -->
                                                <el-checkbox
                                                    :checked="merchantDetail.license_expiry_forever == 1 ? true : false"
                                                    true-label="1" false-label="0" style="margin-left: 16px;"
                                                    @change="changeLicenseExpiryForever">是否长期</el-checkbox>
                                            </div>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="营业执照名称:" prop="merchant_company">
                                            <el-input v-model="merchantDetail.merchant_company" clearable />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2"></el-col>
                                    <el-col :span="10">
                                        <el-form-item label="统一社会信用代码:" prop="license_no">
                                            <el-input v-model="merchantDetail.license_no" clearable />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="法人身份证人像面:" prop="legal_person_id_card_front_url">
                                        <Upload :img="merchantDetail.legal_person_id_card_front_url"
                                            @success="uploadidCardFront" type="ID_CARD" idcard="front" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2"></el-col>
                                <el-col :span="10">
                                    <el-form-item label="法人身份证国徽面:" prop="legal_person_id_card_reverse_url">
                                        <Upload :img="merchantDetail.legal_person_id_card_reverse_url"
                                            @success="uploadidCardReverse" type="ID_CARD" idcard="back" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="法人姓名:" prop="legal_person_name">
                                        <el-input v-model="merchantDetail.legal_person_name" clearable />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2"></el-col>
                                <el-col :span="10">
                                    <el-form-item label="法人证件号:" prop="legal_person_id_card_no">
                                        <el-input v-model="merchantDetail.legal_person_id_card_no" clearable />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="法人证件有效期:" prop="legal_person_id_card_effect_date">
                                        <div style="display: flex;justify-content: space-between;">
                                            <el-date-picker v-model="merchantDetail.legal_person_id_card_effect_date"
                                                value-format="YYYY-MM-DD" type="date" placeholder="生效时间"
                                                :style=timestyle />
                                            <span style="margin:0 10px">至</span>
                                            <el-date-picker v-model="merchantDetail.legal_person_id_card_expiry_date"
                                                :disabled="merchantDetail.legal_person_id_card_expiry_forever == 1 ? true : false"
                                                type="date" placeholder="失效时间" value-format="YYYY-MM-DD"
                                                :style=timestyle />
                                            <el-checkbox
                                                :checked="merchantDetail.legal_person_id_card_expiry_forever == 1 ? true : false"
                                                true-label='1' false-label='0' style="margin-left: 16px;"
                                                @change="changeLegalPersonIdCardExpiryForever">是否长期</el-checkbox>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>

                        <div v-if="step == 2">
                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="商户简称:" prop="merchant_alias">
                                        <el-input v-model="merchantDetail.merchant_alias" placeholder="收款时显示" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2" />
                                <!-- <el-col :span="10">
                  <el-form-item label="商户类型(MCC):" prop="mcc_code">
                    <el-cascader v-model="merchantDetail.mcc_code" :options="mccCode" :style=inputstyle
                      @change=hanldeMccCode />
                  </el-form-item>
                </el-col> -->
                            </el-row>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="一级行业码：" prop="first_mcc_code" style="width: 100%;">
                                        <el-select v-model="merchantDetail.first_mcc_code" style="width: 100%;"
                                            @change="selectTopMcc">
                                            <el-option v-for="item in topMcc" :key="item.busMccId"
                                                :value="item.busMccId" :label="item.busMccId + ' - ' + item.mccName" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2" />
                                <el-col :span="10">
                                    <el-form-item label="二级行业码(MCC)：" prop="mcc_code" style="width: 100%;">
                                        <el-select v-model="merchantDetail.mcc_code" style="width: 100%;">
                                            <el-option v-for="mccCode in mccCodeList" :key="mccCode.mccCode"
                                                :value="mccCode.mccCode"
                                                :label="mccCode.mccCode + ' - ' + mccCode.mccName">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="经营地:" prop="province_code">
                                        <el-cascader :options="addressList" clearable v-model="premises"
                                            :style=inputstyle @change=hanldePremises />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2" />
                                <el-col :span="10">
                                    <el-form-item label="经营地址:" prop="business_address">
                                        <el-input v-model="merchantDetail.business_address" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <!-- <el-row>
                <el-col :span="10">
                  <el-form-item label="经营范围:" prop="business_scope">
                    <el-input v-model="merchantDetail.business_scope" />
                  </el-form-item>
                </el-col>
              </el-row> -->

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="联系人:" prop="contact_person_name">
                                        <el-input v-model="merchantDetail.contact_person_name" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2" />
                                <el-col :span="10">
                                    <el-form-item label="联系人手机号:" prop="contact_person_phone">
                                        <el-input v-model="merchantDetail.contact_person_phone" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <!-- <el-row>
                <el-col :span="10">
                  <el-form-item label="联系人身份证人面相:" prop="legal_person_id_card_front_url">
                    <Upload :img="merchantDetail.legal_person_id_card_front_url" @success="uploadContactPersonIdCardFront" type="ID_CARD" idcard="front"  />
                  </el-form-item>
                </el-col>
              </el-row> -->

                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="店内照片:" prop="store_indoor_url">
                                        <Upload :img="merchantDetail.store_indoor_url" @success="uploadStoreIndoor" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="门头照片:" prop="store_header_url">
                                        <Upload :img="merchantDetail.store_header_url" @success="uploadStoreHeader" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="经营场所-含收银台:" prop="store_cashier_url">
                                        <Upload :img="merchantDetail.store_cashier_url" @success="uploadStoreCashier" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                        </div>

                        <div v-if="step == 3">
                            <!-- 企业 || 个体工商户  -->
                            <div v-if="merchantDetail.business_type == 0 || merchantDetail.business_type == 1">
                                <!-- <div> -->
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="结算账户类型:" prop="account_type">
                                            <el-radio-group v-model="merchantDetail.account_type">
                                                <el-radio label="0" size="large">对私结算</el-radio>
                                                <el-radio label="1" size="large">对公结算</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <!-- <el-row v-if="merchantDetail.settlement_type!=null && merchantDetail.account_type!=1"> -->
                                <el-row v-if="merchantDetail.account_type == 0">
                                    <el-col :span="10">
                                        <el-form-item label="结算类型:" prop="settlement_type">
                                            <el-radio-group v-model="merchantDetail.settlement_type">
                                                <el-radio label="0" size="large">法人结算</el-radio>
                                                <el-radio label="1" size="large">授权非法人结算</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>

                            <!-- 对私结算账户类型 -->
                            <div v-if="merchantDetail.account_type == 0">
                                <div v-if="merchantDetail.settlement_type == 1">
                                    <el-row>
                                        <el-col :span="10">
                                            <el-form-item label="结算人身份证人像面:" prop="settlement_person_id_card_front">
                                                <Upload :img="merchantDetail.settlement_person_id_card_front_url"
                                                    text="人像面" @success="uploadSettlementPersonIdCardFront"
                                                    type="ID_CARD" idcard="front" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2" />
                                        <el-col :span="10">
                                            <el-form-item label="结算人身份证国徽面:" prop="settlement_person_id_card_reverse">
                                                <Upload :img="merchantDetail.settlement_person_id_card_reverse_url"
                                                    text="国徽面" @success="uploadSettlementPersonIdCardReverse"
                                                    type="ID_CARD" idcard="back" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="10">
                                            <el-form-item label="非法人结算授权函:" prop="authorization_letter">
                                                <Upload :img="merchantDetail.authorization_letter_url" text="结算授权函"
                                                    @success="uploadAuthorizationLetter" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2" />
                                        <el-col :span="10">
                                            <el-form-item label="授权书合照(邮政必填):">
                                                <Upload :img="merchantDetail.hold_authorization_letter_url" text="授权书合照"
                                                    @success="uploadHoldAuthorizationLetter" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="10">
                                            <el-form-item label="结算人身份证号码" prop="settlement_person_id_card_no">
                                                <el-input v-model="merchantDetail.settlement_person_id_card_no" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2" />
                                        <el-col :span="10">
                                            <el-form-item label="身份证有效期">
                                                <el-date-picker
                                                    v-model="merchantDetail.settlement_person_id_card_effect_date"
                                                    type="date" placeholder="执照生效时间" value-format="YYYY-MM-DD"
                                                    :style=timestyle />
                                                <span style="margin:0 10px">至</span>
                                                <el-date-picker
                                                    v-model="merchantDetail.settlement_person_id_card_expiry_date"
                                                    type="date"
                                                    :disabled="merchantDetail.settlement_person_id_card_expiry_forever == 1 ? true : false"
                                                    placeholder="执照失效时间" value-format="YYYY-MM-DD" :style=timestyle />
                                                <el-checkbox
                                                    v-model="merchantDetail.settlement_person_id_card_expiry_forever"
                                                    true-label=1 false-label=0
                                                    style="margin-left: 16px;">是否长期</el-checkbox>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-row>
                                        <el-col :span="10">
                                            <el-form-item label="结算账户名" prop="settlement_account_name">
                                                <el-input v-model="merchantDetail.settlement_account_name" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <!-- <div v-else>
                  <el-form-item label="授权书合照(邮政必填):">
                    <Upload :img="merchantDetail.hold_authorization_letter_url" text="授权书合照"
                      @success="uploadHoldAuthorizationLetter" />
                  </el-form-item>
                </div> -->
                            </div>
                            <!-- 对公结算账户类型 -->
                            <div v-if="merchantDetail.account_type == 1 && merchantDetail.business_type != 2">
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="开户许可证:" prop="permit_for_opening_bank_account">
                                            <Upload :img="merchantDetail.permit_for_opening_bank_account_url"
                                                text="开户许可证" @success="uploadPermitOpeningBankAccount" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>

                            <div v-else>
                                <el-row>
                                    <el-col :span="10">
                                        <el-form-item label="银行卡正面:" prop="settlement_card_front_url">
                                            <Upload :img="merchantDetail.settlement_card_front_url"
                                                @success="uploadSettlementCardFront" type="BANK_CARD" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2" />
                                    <el-col :span="10">
                                        <el-form-item label="银行卡反面:" prop="settlement_card_reverse_url">
                                            <Upload :img="merchantDetail.settlement_card_reverse_url"
                                                @success="uploadSettlementCardReverse" type="BANK_CARD" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>

                            <el-row>
                                <el-col :span="10">
                                    <!-- <el-form-item label="手持身份证收款卡照(邮政专用):"> -->
                                    <el-form-item label="补充材料:">
                                        <Upload :img="merchantDetail.hold_id_card_bank_card_url" text="手持身份证收款卡照"
                                            @success="uploadHoldIdCardBankCard" />
                                    </el-form-item>
                                </el-col>
                            </el-row>



                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="结算账户:" prop="settlement_account_no">
                                        <el-input v-model="merchantDetail.settlement_account_no" clearable />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="开户行:" prop="settlement_account_bank_code">
                                        <el-select v-model="merchantDetail.settlement_account_bank_code"
                                            :style=inputstyle clearable>
                                            <el-option v-for="item in banklist" :key="item.id" :label="item.label"
                                                :value="item.value" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2" />
                                <el-col :span="10">
                                    <el-form-item label="银行预留手机号:" prop="settlement_account_phone">
                                        <el-input v-model="merchantDetail.settlement_account_phone" clearable />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="开户行城市:" prop="settlement_account_bank_city_code">
                                        <el-cascader :options="addressList" clearable v-model="whereItIs"
                                            :style=inputstyle @change=bankAddress />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="10">
                                    <el-form-item label="结算户开户支行:" prop="settlement_account_bank_branch_no">
                                        <el-select v-model="merchantDetail.settlement_account_bank_branch_no"
                                            :style=inputstyle filterable clearable>
                                            <el-option v-for="item in branchBanksOptions" :key="item.hanghao"
                                                :label="item.name" :value="item.hanghao" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                        </div>

                    </el-form>
                    <el-footer class="control">

                        <el-button class="btn" type="primary" @click="handlebanck()">退出</el-button>
                        <el-button class="btn" type="primary" @click="preStep()"
                            v-if="step != 1 && step != 0">上一步</el-button>
                        <el-button class="btn" type="primary" @click="nextStep()" v-if="step != 3">下一步</el-button>
                        <el-button class="btn" type="primary" @click="hanldeSeveData" v-if="step == 3">保存修改</el-button>
                    </el-footer>
                </div>
            </div>
        </el-skeleton>
    </el-dialog>
</template>


<style lang="less" scoped>
.container {
    width: 100%;

    .box {
        display: flex;
        align-items: flex-end;
    }

    .tab,
    .main {
        margin: 22px 0;
    }

    .main {
        padding: 6px;

        .interval {
            cursor: not-allowed;
        }
    }

    .control {
        display: flex;
        width: 100%;
        text-align: center;
        background-color: transparent;
        justify-content: center;
        align-items: center;
        align-content: center;

        .btn {
            display: inline-block;
            margin-left: 15px;
        }
    }
}
</style>