<script setup>
import { onMounted, reactive, ref, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router"
import dayjs from "dayjs"
import { ArrowLeft, Plus } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

const $route = useRoute() // 接受
const route = useRouter() // 跳转
const { proxy } = getCurrentInstance()

let pageinfo = reactive({
  total: 0,       // 总条数
  perPage: 0,     // 每页多少条
  currentPage: 0, // 当前页数
  totalPages: 0 // 总页数
})

const pageChange = (page) => {
  console.log(page);
}

const handlebanck = () => {
  route.push('/users/list')
}

// 添加插件
const isShowNewPlugin = ref(false)
const newPluginRef = ref()
// 收银员列表
let cashierData = ref()
// 配置数据
let PluginData = ref({
  name: ""
})

// 收银员插件列表
let cashierListData = ref()

const handleNewPlugin = () => {
  isShowNewPlugin.value = true
}

const rules = reactive({
  cashier_id: [{ required: true, message: '请选择收银员', trigger: 'blur' },]
})

const handleSendPlugin = () => {
  proxy.$refs.newPluginRef.validate((validate) => {
    if (validate) {
      console.log(PluginData.value);
    }
  })
}

// 查询收银插件
// const queryPlugin = () => {
//   console.log(1);
// }

// 查询收银员列表
const queryCashiers = (data) => {
  proxy.$api.merchants.queryCashiers(data).then(res => {
    if (res.status == 200) {
      cashierData.value = res.data.data
    } else {
      ElMessage.error('查询收银员列表失败')
    }
  })
}


onMounted(() => {
  if ($route.query.id != undefined) {
    queryCashiers({ store_id: $route.query.id })
    PluginData.value.store_id = $route.query.id
    PluginData.value.name = $route.query.name
  } else {
    route.push('/users/list')
  }
})

</script>


<template>
  <el-page-header :icon="ArrowLeft" @back="handlebanck">
    <template #content>
      <span> 收银插件 </span>
    </template>
  </el-page-header>

  <div class="searchbar">
    <el-button type="primary" @click="handleNewPlugin()">
      <el-icon style="color:#fff">
        <Plus />
      </el-icon>
      添加插件
    </el-button>
  </div>

  <div class="tablebar">
    <el-table :data="cashierListData" style="width: 100%">
      <el-table-column label="序号" width="80" type="index" align="center">
        <template #default="scope">
          {{ (pageinfo.currentPage -1) * pageinfo.perPage +scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column prop="merchant_name" label="设备激活码" />
      <el-table-column prop="merchant_name" label="收营员" />
      <el-table-column prop="created_at" label="创建时间" width="200px">
        <template #default="scope">
          <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD hh:mm:ss")}} </span>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="空数据" :image-size="200" />
      </template>
    </el-table>
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next" :total=pageinfo.total @current-change="pageChange" :page-size=pageinfo.perPage :current-page=pageinfo.currentPage />
    </div>
  </div>

  <!-- addThePlug-in -->
  <el-dialog v-model="isShowNewPlugin" width="40%" :destroy-on-close="true" title="添加收银插件">
    <el-form ref="newPluginRef" :model="PluginData" :rules="rules" label-width="150px">
      <el-row>
        <el-col :span="20">
          <el-form-item label="门店名称:">
            <el-input :value="PluginData.name" disabled />
          </el-form-item>
        </el-col>
        <el-row>
          <el-col :span="20">
            <el-form-item label="收银员:" prop="cashier_id">
              <el-select v-model="PluginData.cashier_id" placeholder="请选择收银员" size="large">
                <el-option v-for="item in cashierData" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
    </el-form>
    <template #footer>
      <el-button @click="isShowNewPlugin=false">取消</el-button>
      <el-button type="primary" @click="handleSendPlugin()">确定</el-button>
    </template>
  </el-dialog>
  <!-- addThePlug-in end-->

</template>

<style lang="less" scoped>
.searchbar {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tablebar {
  margin: 20px 0;

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>