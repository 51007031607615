export function timeFormat(time, format = 'date') {
    const symbolType = '-'

    const date = new Date(time)
    const Y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const H = getFullTime(date.getHours())
    const i = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    if (format == "date") {
        return Y + symbolType + m + symbolType + d
    }

    if (format == 'datetime') {
        return Y + symbolType + m + symbolType + d + " " + H + ":" + i + ":" + s
    }
}

export function getFullTime(time) {
    return time < 10 ? "0" + time.toString() : time.toString()
}

const time = new Date()

// 获取当前时间
export const GetCurrentTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds())
}

//获取今天结算时间
export const GetTodayEndTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + "23" + ":" + "59" + ":" + "59")
}
// 获取昨天开始时间
export const GetYesterdayTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + (time.getDate() - 1) + " " + "00" + ":" + "00" + ":" + "00")
}

// 获取昨天结束时间
export const GetYesterdayEndTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + (time.getDate() - 1) + " " + "23" + ":" + "59" + ":" + "59")
}

export const GetTodayTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + "00" + ":" + "00" + ":" + "00")
}

export const GetCurrentMonthTime = () => {
    return format(time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + 1 + " " + "00" + ":" + "00" + ":" + "00")
}

export function format(time) {
    const symbolType = '-'

    const date = new Date(time)
    const Y = getFullTime(date.getFullYear())
    const m = getFullTime(date.getMonth() + 1)
    const d = getFullTime(date.getDate())
    const H = getFullTime(date.getHours())
    const i = getFullTime(date.getMinutes())
    const s = getFullTime(date.getSeconds())

    return Y + symbolType + m + symbolType + d + " " + H + ":" + i + ":" + s
}

export const formatDate = (dateString) => {
    const date = new Date(dateString); // 将日期字符串转换为 Date 对象
    const year = date.getFullYear();   // 获取年份
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 获取月份，+1 是因为月份从 0 开始，padStart 保证两位数
    const day = String(date.getDate()).padStart(2, '0'); // 获取日期，padStart 保证两位数

    return `${year}-${month}-${day}`; // 格式化成 "YYYY-MM-DD"
}