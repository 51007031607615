<script setup>
import { ref, onMounted, reactive, getCurrentInstance } from "vue"
import { ElMessage, ElNotification } from 'element-plus'
import Upload from '@/components/CustomUpload'
import Cascader from '@/components/CustomCascader'
import { formatDate } from '@/tools/time'
// import { useRouter } from "vue-router"
// import storeTools from '../../tools/storeTools'

const { appContext } = getCurrentInstance()
const step = ref(1)
const inputstyle = "width:21.25rem"
const timestyle = "width:12.5rem;margin-right:0.75rem"
// const router = useRouter()

// 资质信息
const flairInfoRef = ref()     // 实例化
let flairInfo = reactive({
  business_type: 0,
  license_expiry_forever: "0", //执照是否长期
  legal_person_id_card_expiry_forever: "0"
})

// 基础信息
const basicInfoRef = ref()
let basicInfo = reactive({
  store_indoor: "",
  store_header: "",
  store_cashier: ""
})

// 结算信息 
const settlementinfoRef = ref()
let settlementinfo = reactive({
  // business_type: 2,
  account_type: 0,
  settlement_type: 0,

})

// 校验
const rules = reactive({
  agent_id: [
    { required: true, message: '请选择代理商', trigger: "change" }
  ],
  salesman_id: [
    { required: true, message: '请选择业务员', trigger: "change" }
  ],
  merchant_name: [
    { required: true, message: '请输入商户名', trigger: "blur" },
    { min: 2, max: 30, message: '商户名最小2字符最大30字符', trigger: 'blur' },
  ],
  business_type: [
    { required: true, message: '请选择商户类型', trigger: "change" }
  ],
  license_pic: [
    { required: true, message: '请上传营业执照', trigger: "change" }
  ],
  license_effect_date: [
    { required: true, message: '请选择执照生效时间', trigger: "change" }
  ],
  legal_person_id_card_front: [
    { required: true, message: '请上传身份证正面', trigger: "change" }
  ],
  legal_person_id_card_reverse: [
    { required: true, message: '请上传身份证反面', trigger: "change" }
  ],
  merchant_company: [
    { required: true, message: '请输入营业执照名称', trigger: "blur" }
  ],
  license_no: [
    { required: true, message: '请输入统一社会信用代码', trigger: "blur" },
    { min: 18, max: 18, message: '社会信用代码最小18位', trigger: 'blur' },
    { pattern: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/, message: '请输入统一社会信用代码', trigger: 'blur' }
  ],
  legal_person_id_card_no: [
    { required: true, message: '请输入法人身份证号', trigger: "blur" },
    { min: 15, max: 18, message: '身份证号最小15位最大18位,请正确输入', trigger: 'blur' },
    { pattern: /^[1-9]\d{5}/, message: '请输入正确身份证号', trigger: 'blur' }
  ],
  legal_person_name: [
    { required: true, message: '请输入法人姓名', trigger: "blur" }
  ],
  legal_person_id_card_effect_date: [
    { required: true, message: '请选择身份证生效时间', trigger: "change" }
  ],
  legal_person_holding_id_card: [
    { required: true, message: '请上传手持身份证照片', trigger: "change" }
  ],
  merchant_alias: [
    { required: true, message: '请输入商户简称', trigger: "blur" },
    { min: 2, max: 30, message: '简称不得低于2字符', trigger: 'blur' },
  ],
  mcc_code: [
    { required: true, message: '请选择商户类别', trigger: "change" }
  ],
  business_address: [
    { required: true, message: '请输入经营地址街道详细信息', trigger: "blur" }
  ],
  province_code: [
    { required: true, message: '请选择经营地', trigger: "change" }
  ],
  business_scope: [
    { required: true, message: '请输入经营范围', trigger: "blur" }
  ],
  store_indoor: [
    { required: true, message: '请上传店内环境照片', trigger: "change" }
  ],
  store_header: [
    { required: true, message: '请上传门头照片', trigger: "change" }
  ],
  store_cashier: [
    { required: true, message: '请上传经营场所包含收银台', trigger: "change" }
  ],
  account_type: [
    { required: true, message: '请选择结算账户类型', trigger: "change" }
  ],
  settlement_type: [
    { required: true, message: '请选择结算类型', trigger: "change" }
  ],
  settlement_card_front: [
    { required: true, message: '请上传银行卡正面照片', trigger: "change" }
  ],
  settlement_card_reverse: [
    { required: true, message: '请上传银行卡反面照片', trigger: "change" }
  ],
  permit_for_opening_bank_account: [
    { required: true, message: '请上传开户许可证照片', trigger: "change" }
  ],
  settlement_account_no: [
    { required: true, message: '请输入结算银行卡卡号', trigger: "blur" },
    { min: 16, max: 19, message: '银行卡最小16位最大19位,请正确输入', trigger: 'blur' },
  ],
  settlement_account_bank_code: [
    { required: true, message: '请选择开户行', trigger: "change" }
  ],
  settlement_account_phone: [
    { required: true, message: '请输入银行预留手机号', trigger: "blur" },
    { min: 11, max: 11, message: '手机号11位,请正确输入', trigger: 'blur' },
    { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号号格式不对', trigger: 'blur' }
  ],
  settlement_account_bank_province_code: [
    { required: true, message: '请选择开户省', trigger: "change" }
  ],
  settlement_account_bank_city_code: [
    { required: true, message: '请选择开户市', trigger: "change" }
  ],
  settlement_account_bank_branch_no: [
    { required: true, message: '请选择结算户开户支行', trigger: "change" }
  ],
  contact_person_name: [
    { required: true, message: '请输入联系人姓名', trigger: "blur" }
  ],
  contact_person_phone: [
    { required: true, message: '请输入联系人手机号', trigger: "blur" },
    { min: 11, max: 11, message: '手机号11位,请正确输入', trigger: 'blur' },
    { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号号格式不对', trigger: 'blur' }
  ],
  settlement_person_id_card_front: [
    { required: true, message: '此项为必填' },
  ],
  settlement_person_id_card_reverse: [
    { required: true, message: '此项为必填' },
  ],
  authorization_letter: [
    { required: true, message: '此项为必填' },
  ],
  settlement_account_name: [
    { required: true, message: '此项为必填' },
  ],
  settlement_person_id_card_no: [
    { required: true, message: '此项为必填' },
  ],
  settlementLegalPersonIdCardDate: [
    { required: true, message: '此项为必填' },
  ],

})

// 图片上传回调 START
const uploadLicensePic = (imgId, imgUrl, file) => {


  // 替换license_name中的()为中文括号（）
  const licenseNameWithChineseBrackets = file.license_name.replace(/\(/g, '（').replace(/\)/g, '）');
  flairInfo.license_pic = imgId
  flairInfo.license_no = file.license_no
  flairInfo.merchant_company = licenseNameWithChineseBrackets
  flairInfo.merchant_name = licenseNameWithChineseBrackets
  basicInfo.business_address = file.license_address

  if (file.license_type != '个体工商户') {
    flairInfo.company_type = 2
  } else {
    flairInfo.company_type = 1
  }

  flairInfo.license_effect_date = formatDate(file.register_date)
  flairInfo.license_expiry_date = formatDate(file.expire_date)
  if (file.expire_date == '9999-12-31') {
    flairInfo.license_expiry_forever = true
  } else {
    flairInfo.license_expiry_forever = false
  }
}
const uploadidCardFront = (imgId, imgUrl, file) => {
  console.log(imgId, imgUrl, file, 'imgId, imgUrl, file');
  flairInfo.legal_person_id_card_front = imgId
  flairInfo.legal_person_name = file.name
  flairInfo.legal_person_id_card_no = file.id_card_no
}
const uploadidCardReverse = (imgId, imgUrl, file) => {
  flairInfo.legal_person_id_card_reverse = imgId
  flairInfo.legal_person_id_card_effect_date = formatDate(file.is_use_date)
  flairInfo.legal_person_id_card_expiry_date = formatDate(file.expire_date)

  flairInfo.legal_person_id_card_expiry_forever = true
}
// 小微商户手持身份证照片
const uploadHoldinIdCard = (id) => {
  if (id) {
    flairInfo.legal_person_holding_id_card = id
  }
}
// 资质信息 end

// 地址选择成功回调
const businessCity = (value) => {
  if (value != null) {
    basicInfo.province_code = value[0]  // 省
    basicInfo.city_code = value[1]      // 市
    basicInfo.district_code = value[2]  // 区
  }
}
const uploadStoreIndoor = (id) => {
  if (id) {
    basicInfo.store_indoor = id
  }
}
const uploadStoreHeader = (id) => {
  if (id) {
    basicInfo.store_header = id
  }
}
const uploadStorecashier = (id) => {
  if (id) {
    basicInfo.store_cashier = id
  }
}
// 基础信息 end
const uploadSettlementCardFront = (imgId, imgUrl, file) => {
  settlementinfo.settlement_card_front = imgId
  settlementinfo.settlement_account_no = file.bank_card_number
}

//结算人身份证人像面
const uploadSettlementPersonIdCardFront = (imgId, imgUrl, file) => {
  settlementinfo.settlement_person_id_card_front = imgId
  settlementinfo.settlement_person_name = file.name
  settlementinfo.settlement_person_id_card_no = file.id_card_no
}

//结算人身份证国徽面
const uploadSettlementPersonIdCardReverse = (imgId, imgUrl, file) => {
  settlementinfo.settlement_person_id_card_reverse = imgId
  settlementinfo.settlement_person_id_card_effect_date = formatDate(file.is_use_date)
  settlementinfo.settlement_person_id_card_expiry_date = formatDate(file.expire_date)

  settlementinfo.settlement_person_id_card_expiry_forever = true
}

//非法人结算授权函
const uploadAuthorizationLetter = (id) => {
  if (id) {
    settlementinfo.authorization_letter = id
  }
}

//授权书合照
const uploadHoldAuthorizationLetter = (id) => {
  if (id) {
    settlementinfo.hold_authorization_letter = id
  }
}

const uploadSettlementCardReverse = (id) => {
  if (id) {
    settlementinfo.settlement_card_reverse = id
  }
}

// // 收款人手持身份证收款卡照
const uploadHoldIdCardBankCard = (id) => {
  settlementinfo.hold_id_card_bank_card = id
}


const uploadPermitForOpeningBankAccount = (id) => {
  if (id) {
    settlementinfo.permit_for_opening_bank_account = id
  }
}
// 地址回调
const settlementAccountArea = (address) => {
  if (address != null) {
    settlementinfo.settlement_account_bank_province_code = address[0] // 省
    settlementinfo.settlement_account_bank_city_code = address[1] // 市
    settlementinfo.settlement_account_bank_district_code = address[2] // 区
  }
}

// 图片上传回调 END

const compayType = [
  // 2023-1-10 正式版
  { id: 1, value: "个体工商户" },
  { id: 2, value: "有限责任公司" },
  { id: 3, value: "非公司企业法人" },
  { id: 4, value: "私营合伙企业" },
  { id: 5, value: "私营独资企业" },
  { id: 6, value: "股份有限责任公司" },
]

const handlePrevStep = () => {
  step.value -= 1
}

// 校验 资质信息 并前往下一步
const handleNextBasicInfo = async (ElRef) => {
  if (!ElRef) return
  await ElRef.validate((valid) => {
    if (valid) {
      step.value += 1
    } else {
      ElNotification({
        title: '表单验证未通过',
        message: "请检查是否有必填项未填",
        type: 'warning',
      })
    }
  })
}
// 校验 基础信息 并前往下一步
const handleNextSettlementInfo = async (ElRef) => {
  if (!ElRef) return
  await ElRef.validate((valid) => {
    if (valid) {
      basicInfo.mcc_code = basicInfo.mcc_code[1]
      step.value += 1
    } else {
      ElNotification({
        title: '表单验证未通过',
        message: "请检查是否有必填项未填",
        type: 'warning',
      })
    }
  })
}
// 校验 结算信息 并调用发送请求
const handleRulesBtn = async (ElRef) => {
  if (!ElRef) return
  await ElRef.validate((valid) => {
    if (valid) {
      mergeData()
    } else {
      ElNotification({
        title: '表单验证未通过',
        message: "请检查是否有必填项未填",
        type: 'warning',
      })
    }
  })
}

const mergeData = () => {
  let data = Object.assign(flairInfo, basicInfo, settlementinfo)
  createAMerchant(data)
}

const createAMerchant = (data) => {
  appContext.config.globalProperties.$api.merchants.create(data).then(res => {
    if (res.status == 200) {
      ElNotification({
        title: "成功",
        message: "新增商户成功",
        type: 'success',
      })
      step.value = 1
    } else {
      ElNotification({
        title: `错误代码${res.status
          }`,
        message: "新增商户失败",
        type: 'error',
      })
    }
  })
}

const handleSelectAgents = (value) => {
  querySalesmanList(value)
}

const handleBusinessType = (value) => {
  if (value == 1) {
    flairInfo.company_type = 1
  } else {
    delete flairInfo.company_type
  }

  // flairInfo.company_type = val


}


const allPages = {
  page: 1,
  pageSize: 999
}

const errorMlessage = (msg) => {
  ElMessage.error(`${msg}查询失败, 请稍后重试`)
}

// 代理商列表
let agentsList = ref()
const queryAgentsList = () => {
  appContext.config.globalProperties.$api.agentsList(allPages).then(res => {
    if (res.status == 200) {
      agentsList.value = res.data
    } else {
      errorMlessage('代理商')
    }
  })
}

// 业务员列表
let salesmanList = ref()
const querySalesmanList = (salesmanid) => {
  allPages.agent_id = salesmanid
  appContext.config.globalProperties.$api.salesman.list(allPages).then(res => {
    if (res.status == 200) {
      salesmanList.value = res.data.data
    } else {
      errorMlessage('业务员')
    }
  })
}

// 行业类别
let industryType = ref()
const industryProps = {
  label: 'name',
  value: 'code',
  children: 'children'
}

const queryIndustryType = () => {
  appContext.config.globalProperties.$api.mcc.list().then(res => {
    if (res.status == 200) {
      industryType.value = res.data.data
    } else {
      errorMlessage('行业类别')
    }
  })
  quertBanks()
}

let banksOptions = ref()
// 查询开户行
const quertBanks = () => {
  appContext.config.globalProperties.$api.banks.list().then(res => {
    if (res.status == 200) {
      banksOptions.value = res.data.data
    } else {
      errorMlessage('开户行')
    }
  })
}


// 开户行
let branchAddress = reactive({
  page: 1,
  pageSize: 9999
})
// 开户行
const bankArea = (value) => {
  branchAddress.bank_id = value
}
// 开户行区域
const bankAddress = (value) => {
  if (value != null) {
    branchAddress.province_code = value[0]
    branchAddress.city_code = value[1]
    quertBankList(branchAddress)
  }
}

let branchBanksOptions = ref()
// 查询开户支行地址
const quertBankList = (data) => {
  appContext.config.globalProperties.$api.banks.branchBanks(data).then(res => {
    if (res.status == 200) {
      branchBanksOptions.value = res.data.data
    } else {
      errorMlessage('开户支行')
    }
  })
}

onMounted(() => {
  queryAgentsList()
  queryIndustryType()
})


</script>

<template>
  <div class="steps">
    <el-steps :active="step" align-center finish-status="success">
      <el-step title="资质信息" />
      <el-step title="基础信息" />
      <el-step title="结算信息" />
    </el-steps>
  </div>

  <div class="content">
    <div v-show="step == 1">
      <el-form ref="flairInfoRef" :model="flairInfo" label-width="200px" label-position="right" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="代理商：" prop="agent_id">
              <el-select v-model="flairInfo.agent_id" placeholder="请选择代理商" clearable @change="handleSelectAgents"
                :style=inputstyle>
                <el-option v-for="item in agentsList" :key="item.id" :label="item.name + '-' + item.phone"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务员：" prop="salesman_id">
              <el-select v-model="flairInfo.salesman_id" placeholder="请选择业务员" clearable :style=inputstyle>
                <el-option v-for="item in salesmanList" :key="item.id" :label="item.name + '-' + item.phone"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="商户类型：" prop="business_type">
              <el-radio-group v-model="flairInfo.business_type" size="large" @change="handleBusinessType">
                <el-radio-button :label=0>企业</el-radio-button>
                <el-radio-button :label=1>个体工商</el-radio-button>
                <el-radio-button :label=2>小微商户</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 企业 个体工商户 -->
        <div v-if="flairInfo.business_type == 0 || flairInfo.business_type == 1">
          <el-row>
            <el-col :span="12">
              <el-form-item label="营业执照：" prop="license_pic">
                <Upload @success="uploadLicensePic" text="营业执照" type="LICENSE" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商户名：" prop="merchant_name">
                <el-input v-model="flairInfo.merchant_name" clearable :style=inputstyle placeholder="上传营业执照时自动填写,请校对" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="营业执照名称：" prop="merchant_company">
                <el-input v-model="flairInfo.merchant_company" clearable :style=inputstyle placeholder="与营业执照一致" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码：" prop="license_no">
                <el-input v-model="flairInfo.license_no" clearable :style=inputstyle placeholder="请输入统一社会信用代码" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="公司类型：">
                <el-select v-model="flairInfo.company_type" placeholder="请选择公司类型" :style=inputstyle>
                  <el-option v-for="item in compayType" :key="item.id" :label="item.value" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="执照生效时间：" prop="license_effect_date">
                <el-date-picker v-model="flairInfo.license_effect_date" type="date" placeholder="请选择执照生效时间"
                  value-format="YYYY-MM-DD" :style=timestyle />
                <el-date-picker v-model="flairInfo.license_expiry_date" type="date" placeholder="请选择执照失效时间"
                  value-format="YYYY-MM-DD" :style=timestyle />
                <div class="foralongtime">
                  <el-checkbox v-model="flairInfo.license_expiry_forever" true-label=1 false-label=0
                    size="small">是否长期</el-checkbox>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

        </div>
        <!-- 小微商户 -->
        <div v-else>
          <el-row>
            <el-col :span="12">
              <el-form-item label="商户名：" prop="merchant_name">
                <el-input v-model="flairInfo.merchant_name" clearable :style=inputstyle placeholder="商户_XXX 例:商户_张三" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="法人手持身份证：" prop="legal_person_holding_id_card">
                <Upload @success="uploadHoldinIdCard" text="手持身份证" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>


        <el-row>
          <el-col :span="12">
            <el-form-item label="法人身份证正面：" prop="legal_person_id_card_front">
              <Upload @success="uploadidCardFront" text="身份证正面" type="ID_CARD" idcard="front" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人身份证反面：" prop="legal_person_id_card_reverse">
              <Upload @success="uploadidCardReverse" text="身份证反面" type="ID_CARD" idcard="back" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="法人姓名：" prop="legal_person_name">
              <el-input v-model="flairInfo.legal_person_name" clearable :style=inputstyle placeholder="请输入法人姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人身份证号：" prop="legal_person_id_card_no">
              <el-input v-model="flairInfo.legal_person_id_card_no" clearable :style=inputstyle
                placeholder="请输入法人身份证号" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="法人身份证有效期：" prop="legal_person_id_card_effect_date">
              <el-date-picker v-model="flairInfo.legal_person_id_card_effect_date" type="date" placeholder="请选择身份证生效时间"
                value-format="YYYY-MM-DD" :style=timestyle />
              <el-date-picker v-model="flairInfo.legal_person_id_card_expiry_date" type="date" placeholder="请选择身份证失效时间"
                value-format="YYYY-MM-DD" :style=timestyle />
              <div class="foralongtime">
                <el-checkbox v-model="flairInfo.legal_person_id_card_expiry_forever" true-label=1 false-label=0
                  size="small">是否长期</el-checkbox>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="control">
        <el-button type="primary" @click="handleNextBasicInfo(flairInfoRef)">下一步</el-button>
      </div>
    </div>

    <div v-show="step == 2">
      <el-form ref="basicInfoRef" :model="basicInfo" label-width="200px" label-position="right" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商户简称：" prop="merchant_alias">
              <el-input v-model="basicInfo.merchant_alias" clearable placeholder="请输入商户简称" :style=inputstyle />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户类别(MCC): " prop="mcc_code">
              <el-cascader :options="industryType" v-model="basicInfo.mcc_code" clearable placeholder="请选择商户类别"
                :style=inputstyle :props="industryProps" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="经营地：" prop="province_code">
              <Cascader @success="businessCity" :style=inputstyle />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经营地址：" prop="business_address">
              <el-input v-model="basicInfo.business_address" clearable placeholder="请输入经营地址街道详细信息" :style=inputstyle />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 
        <el-row>
          <el-col :span="12">
            <el-form-item label="经营范围: " prop="business_scope">
              <el-input v-model="basicInfo.business_scope" clearable placeholder="请输入经营范围" :style=inputstyle />
            </el-form-item>
          </el-col>
        </el-row> -->

        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人姓名: " prop="contact_person_name">
              <el-input v-model="basicInfo.contact_person_name" clearable placeholder="请输入联系人姓名" :style=inputstyle />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人手机号: " prop="contact_person_phone">
              <el-input v-model="basicInfo.contact_person_phone" clearable placeholder="请输入联系人手机号" :style=inputstyle />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="店内照片: " prop="store_indoor">
              <Upload @success="uploadStoreIndoor" text="店内照片" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="门头照片: " prop="store_header">
              <Upload @success="uploadStoreHeader" text="门头照片" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="经营场所-含收银台: " prop="store_cashier">
              <Upload @success="uploadStorecashier" text="经营场所" />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="经营场所-含收银台: " prop="store_cashier">
              <Upload @success="uploadStorecashier" text="经营场所" />
            </el-form-item>
          </el-col>
        </el-row> -->

      </el-form>

      <div class="control">
        <el-button @click="handlePrevStep">上一步</el-button>
        <el-button type="primary" @click="handleNextSettlementInfo(basicInfoRef)">下一步</el-button>
      </div>

    </div>

    <div v-show="step == 3">
      <el-form ref="settlementinfoRef" :model="settlementinfo" label-width="200px" label-position="right"
        :rules="rules">
        <!-- 企业和个体商户 -->
        <div v-if="flairInfo.business_type == 0 || flairInfo.business_type == 1">
          <el-row>
            <el-col :span="12">
              <el-form-item label="结算账户类型: " prop="account_type">
                <el-radio-group v-model="settlementinfo.account_type">
                  <el-radio :label=0>对私结算</el-radio>
                  <el-radio :label=1>对公结算</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 对私 -->
          <div v-if="settlementinfo.account_type == 0">
            <el-row>
              <el-col :span="12">
                <el-form-item label="结算类型: " prop="settlement_type">
                  <el-radio-group v-model="settlementinfo.settlement_type">
                    <el-radio :label=0>法人结算</el-radio>
                    <el-radio :label=1>授权非法人结算</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="银行卡正面: " prop="settlement_card_front">
                  <Upload @success="uploadSettlementCardFront" text="银行卡正面" type="BANK_CARD" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="银行卡反面: " prop="settlement_card_reverse">
                  <Upload @success="uploadSettlementCardReverse" text="银行卡反面" />
                </el-form-item>
              </el-col>
            </el-row>

            <div v-if="settlementinfo.settlement_type == 1">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="结算人身份证人像面:" prop="settlement_person_id_card_front">
                    <Upload text="人像面" @success="uploadSettlementPersonIdCardFront" type="ID_CARD" idcard="front" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="结算人身份证国徽面:" prop="settlement_person_id_card_reverse">
                    <Upload text="国徽面" @success="uploadSettlementPersonIdCardReverse" type="ID_CARD" idcard="back" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="非法人结算授权函:" prop="authorization_letter">
                    <Upload text="结算授权函" @success="uploadAuthorizationLetter" />
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="授权书合照(邮政必填):">
                    <Upload text="授权书合照" @success="uploadHoldAuthorizationLetter" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="10">
                  <el-form-item label="结算人身份证号码" prop="settlement_person_id_card_no">
                    <el-input v-model="settlementinfo.settlement_person_id_card_no" />
                  </el-form-item>
                </el-col>
                <el-col :span="2" />
                <el-col :span="10">
                  <el-form-item label="身份证有效期">
                    <el-date-picker v-model="settlementinfo.settlement_person_id_card_effect_date" type="date"
                      placeholder="执照生效时间" value-format="YYYY-MM-DD" :style=timestyle />
                    <span style="margin:0 10px">至</span>
                    <el-date-picker v-model="settlementinfo.settlement_person_id_card_expiry_date" type="date"
                      :disabled="settlementinfo.settlement_person_id_card_expiry_forever == 1 ? true : false"
                      placeholder="执照失效时间" value-format="YYYY-MM-DD" :style=timestyle />
                    <el-checkbox v-model="settlementinfo.settlement_person_id_card_expiry_forever" true-label=1
                      false-label=0 style="margin-left: 16px;">是否长期</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="10">
                  <el-form-item label="结算账户名" prop="settlement_account_name">
                    <el-input v-model="settlementinfo.settlement_account_name" />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

          </div>
          <!-- 对公 -->
          <div v-if="settlementinfo.account_type == 1">
            <el-row>
              <el-col :span="12">
                <el-form-item label="开户许可证: " prop="permit_for_opening_bank_account">
                  <Upload @success="uploadPermitForOpeningBankAccount" text="开户许可证" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>



        </div>
        <!-- 小微商户 -->
        <div v-else>
          <el-row>
            <el-col :span="12">
              <el-form-item label="银行卡正面: " prop="settlement_card_front">
                <Upload @success="uploadSettlementCardFront" text="银行卡正面" type="BANK_CARD" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="银行卡反面: " prop="settlement_card_reverse">
                <Upload @success="uploadSettlementCardReverse" text="银行卡反面" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-row>
          <el-col :span="24">
            <!-- <el-form-item label="手持身份证收款卡照(邮政专用):"> -->
            <el-form-item label="补充材料:">
              <Upload :img="settlementinfo.hold_id_card_bank_card_url" text="手持身份证收款卡照"
                @success="uploadHoldIdCardBankCard" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="银行卡卡号: " prop="settlement_account_no">
              <el-input v-model="settlementinfo.settlement_account_no" clearable placeholder="请输入结算银行卡卡号"
                :style=inputstyle />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="开户行: " prop="settlement_account_bank_code">
              <el-select v-model="settlementinfo.settlement_account_bank_code" placeholder="请选择开户行" clearable
                :style=inputstyle @change=bankArea>
                <el-option v-for="item in banksOptions" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行预留手机号: " prop="settlement_account_phone">
              <el-input v-model="settlementinfo.settlement_account_phone" clearable placeholder="请输入银行预留手机号"
                :style=inputstyle />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="开户行城市: " prop="settlement_account_bank_province_code">
              <Cascader @success="settlementAccountArea" :style=inputstyle clearable placeholder="请选择开户行区域"
                @change=bankAddress />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="结算户开户支行: " prop="settlement_account_bank_branch_no">
              <el-select v-model="settlementinfo.settlement_account_bank_branch_no" placeholder="请选择开户支行" clearable
                style="width: 100%;" filterable>
                <el-option v-for="item in branchBanksOptions" :key="item.hanghao" :label="item.name"
                  :value="item.hanghao" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div class="control">
        <el-button @click="handlePrevStep">上一步</el-button>
        <el-button type="primary" @click="handleRulesBtn(settlementinfoRef)">提交</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.steps {
  width: 80%;
  margin: 0 auto;
  height: 5rem;
  box-sizing: border-box;
}

.content {
  padding: 1.25rem 0;
  width: 88%;
  margin: 0 auto;

  .foralongtime {
    margin-left: 0.75rem;
  }

  .control {
    margin: 1.25rem 0;
    display: flex;
    justify-content: center;
  }
}
</style>
