<template>
  <div class="box">
    <!-- <div class="rectangle-box">
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
            <div class="rectangle"></div>
        </div> -->
    <div class="login">
      <div class="login-header">
        <div>鑫易付总后台登录</div>
        <div>Hi 欢迎来到鑫易付</div>
      </div>
      <div class="login-main">
        <el-form :model="form" ref="ruleFormRef" :rules="rules" status-icon>
          <el-form-item>
            <el-input type="text" v-model="form.username" placeholder="请输入账号" :autofocus="true" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="form.password" show-password :auto-insert-space="true" placeholder="请输入密码"
              @keyup.enter="hanldeCheck" />
          </el-form-item>
          <el-from-item>
            <el-button color="#fff" :dark="true" size="large" type="primary" @click="hanldeCheck"
              v-loading="isLoading">{{ loginText }}</el-button>
          </el-from-item>
        </el-form>
        <vcode :show="codeShow" @success="codeSuccess" @close="codeClose"></vcode>
      </div>
      <!-- <div class="resetPassword">
          <text @click="goToresetPassword">修改密码</text>
        </div> -->
    </div>
  </div>
</template>

<script>
// import message from '@/tools/messageTools.js'
// import { ElMessageBox } from 'element-plus';
import { ElMessage } from 'element-plus';
import vcode from "@/components/CustomValidateCode/index.vue"
export default {
  components: {
    vcode,
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      loginText: '登 录',
      isLoading: false,
      codeShow: false,
      err: {
        count: 0
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      const r = await this.$api.login({ username: this.form.username, password: this.form.password })
      if (r.status == 200 && r.success) {
        const token = {
          access_token: r.data.access_token,
          expires_in: r.data.expires_in,
          token_type: r.data.token_type,
          begin_time: new Date().getTime()
        }
        sessionStorage.setItem('token', JSON.stringify(token))
        sessionStorage.setItem('agent_user', JSON.stringify(r.data.user))
        this.$router.push({ path: '/dashboard' })
      } else {
        ElMessage({ message: `${r.error.message}`, type: 'warning', })
        // r.error.code=="error" ? this.err.count += 1: this.err.count=0
        // if (this.err.count==3) {
        //   ElMessage.error('账号已被锁定,请10分钟后再尝试')
        //   this.err.setLockTime=new Date()
        // }else{
        //   ElMessage({ message: `${r.error.message}, 超过三次 账号锁定10分钟 第${this.err.count}次.`,type: 'warning',})
        // }
        this.isLoading = false
        this.loginText = '登 录'

        // message.message(r)


        // if(this.err.loginIp!=null && this.err.loginCount==2){
        //   ElMessageBox.confirm(
        //   '检测到该账号频繁登陆，本设备暂被禁止登陆，请稍后重试',
        //   '警告',
        //   {
        //     confirmButtonText: '知道啦',
        //     cancelButtonText: '返回',
        //     type: 'warning',
        //     center: true,
        //   },
        // )
        // }else{
        //   ElMessageBox.confirm(
        //     '该账号已在其他设备登陆',
        //     '警告',
        //     {
        //       confirmButtonText: '知道啦',
        //       cancelButtonText: '返回',
        //       type: 'warning',
        //       center: true,
        //     },
        //   )
        // }
      }
    },
    // 触发校验
    async hanldeCheck() {
      this.$refs.ruleFormRef.validate((valid) => {
        this.loginText = '登 录 中...'
        this.isLoading = true
        if (valid) {
          this.codeShow = true
        } else {
          this.isLoading = false
          this.loginText = '登 录'
          return false;
        }
      })
    },
    codeSuccess(msg) {
      console.log(`总共耗时:${msg.toFixed(2)} 🕙`);
      this.codeShow = false;
      this.onSubmit()
    },
    codeClose() {
      this.codeShow = false;
      this.isLoading = false
      this.loginText = '登 录'
    },
    goToresetPassword() {
      this.$router.push({ path: '/resetPassword' })
    }
  },
}
</script>



<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom left, #2d3e69, #4d6fbf);
  overflow: hidden;

  // .rectangle {
  //     width: 800px;
  //     height: 800px;
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     background-image: linear-gradient(to bottom left, #2d3e69, #4d6fbf)
  // }

  // .rectangle:nth-child(1) {
  //     transform: rotate(300deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(2) {
  //     transform: rotate(200deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(3) {
  //     transform: rotate(100deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(4) {
  //     left: 50%;
  // }

  // .rectangle:nth-child(5) {
  //     transform: rotate(-100deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(6) {
  //     transform: rotate(-200deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(7) {
  //     transform: rotate(-300deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(8) {
  //     transform: rotate(-100deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(9) {
  //     transform: rotate(-100deg);
  //     left: 50%;
  // }

  // .rectangle:nth-child(10) {
  //     transform: rotate(300deg);
  // }

  // .rectangle:nth-child(11) {
  //     transform: rotate(200deg);
  // }

  // .rectangle:nth-child(12) {
  //     transform: rotate(100deg);
  // }

  .login {
    width: 400px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    margin-top: -150px;
    margin-left: -200px;
    background-image: linear-gradient(to top, #4d6fbf, #2d3e69);
    border-radius: 15px;

    @width: 250px;

    .login-header {
      width: 100%;
      height: 80px;
      line-height: 80px;
      padding-top: 15px;
      color: #fff;

      >div {
        width: @width;
        height: 20px;
        line-height: 20px;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
      }

      >div:first-child {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        text-align: center;
      }
    }

    .login-main {
      width: @width;
      margin: 0 auto;
      margin-top: 25px;

      .el-button--primary {
        background: #2d3e69 !important;
        border-color: #2d3e69 !important;
        color: #fff !important;

        .el-loading-mask {
          background-color: transparent !important;
        }
      }

    }
    .resetPassword {
        display: flex;
        justify-content: flex-end;
        color: #FFF;
        margin-right: 20px;
      }

      .resetPassword:hover {
        color: #2d3e69;
      }
  }
}
</style>