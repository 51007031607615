<template>
  <div>
    <el-card style="max-width: 100%">
      <div style="display: flex;gap: 20px;align-items: flex-start;flex-wrap: wrap;">
        <el-form :model="form" inline>
          <el-form-item label="代理：">
            <el-select v-model="form.agent_id" filterable clearable placeholder="请选择">
              <el-option v-for="item in agentList" :key="item.value" :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="时间：">
            <el-date-picker v-model="form.date" value-format="YYYY-MM-DD" type="daterange" align="right" unlink-panels
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="变动类型">
            <el-select v-model="form.action" placeholder="可以筛选充值记录" style="width:200px;">
              <el-option label="全部" value=""></el-option>
              <el-option label="余额增加" value="1"></el-option>
              <el-option label="余额扣除" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="handleSearch" plain>查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </div>
      </div>
    </el-card>
  </div>
  <div style="margin-top: 20px;">
    <el-card>
      <div class="table-main">
        <el-table :data="tableData" v-loading="isloading" :border="true" stripe style="width: 100%">
          <el-table-column label="流水号" prop="sn" width="160"/>
          <el-table-column label="代理商编号" prop="agent.no" width="120" show-overflow-tooltip/>
          <el-table-column label="代理商" prop="agent.company" show-overflow-tooltip width="160"/>
          <el-table-column label="备注" prop="remark" show-overflow-tooltip width="200"/>
          <el-table-column label="变动" prop="status" width="200">
            <template #default="{ row }">
              <el-tag :type="row.action === 2 ? 'success' : 'danger'">
                {{ row.action === 2 ? `-${row.change_amount} 万` : `+${row.change_amount} 万` }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="余额" prop="left_amount" width="160" show-overflow-tooltip>
            <template #default="{ row }">
              {{ row.left_amount + ' 万' }}
            </template>
          </el-table-column>
          <el-table-column label="时间" prop="created_at" width="180" show-overflow-tooltip/>
        </el-table>
        <div class="pagination" style="margin-top: 10px;">
          <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
                         @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
                         :hide-on-single-page="false">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import {getCurrentInstance, nextTick, onMounted, ref} from 'vue';
import {useRoute} from "vue-router";

const {proxy} = getCurrentInstance();

const agentId = ref('');
const route = useRoute();
agentId.value = route.query?.agent_id || '';
const form = ref({
  agent_id: agentId,
  action: '',
  date: ''
});

const tableData = ref([]);
let isloading = ref(false);
let pageinfo = ref({
  perPage: 0, // 每页多少条
  total: 0, // 共计多少
  totalPages: 0, // 共计多少页
  currentPage: 1, // 当前页数
});

const pageChange = (page) => {
  hasSearch(page);
};

const hasSearch = (page) => {
  isloading.value = true;
  let info = {...form.value, page: page};
  queryList(info);
};

const handleSearch = () => {
  isloading.value = true;
  pageinfo.value.currentPage = 1; // Reset to the first page
  let info = {...form.value, page: 1};
  queryList(info);
};
const resetSearch = () => {
  form.value = {};
  handleSearch();
};

const queryList = async (data) => {
  const response = await proxy.$api.agents.apiWalletLogs(data);
  tableData.value = response.data.data;
  pageinfo.value = {
    perPage: response.data.pagination.perPage,
    total: response.data.pagination.total,
    totalPages: response.data.pagination.totalPages,
    currentPage: response.data.pagination.currentPage
  };
  isloading.value = false;
};

// 查询代理列表
const agentList = ref([]);
const queryAgentList = async () => {
  proxy.$api.agentsList({parent_id: "null", pageSize: 999}).then((res) => {
    if (res.status == 200) {
      agentList.value = res.data.map((item) => ({
        value: item.id,
        label: item.company + "（" + item.name + ")",
      }));
    }
  });
  nextTick(() => {
    form.value.agent_id = agentId;
  });
};

// 页面挂载时执行
onMounted(() => {
  handleSearch()
  queryAgentList();
});

</script>
