<template>
  <div>
    <el-card style="max-width: 100%">
      <div style="display: flex;gap: 20px;align-items: flex-start;flex-wrap: wrap;">
        <el-form :model="form">
          <el-form-item label="代理：">
            <el-select v-model="form.agent_id" filterable clearable placeholder="请选择">
              <el-option v-for="item in agentList" :key="item.value" :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="handleSearch" plain>查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button @click="onClickAdd" :icon="Plus" type="primary">开通服务</el-button>
        </div>
      </div>
    </el-card>
  </div>
  <div style="margin-top: 20px;">
    <el-card>
      <div class="table-main">
        <el-table :data="tableData" v-loading="isloading" :border="true" stripe style="width: 100%">
          <el-table-column label="代理商编号" prop="agent.no" width="120" show-overflow-tooltip/>
          <el-table-column label="代理商" prop="agent.company" show-overflow-tooltip width="160"/>
          <el-table-column label="余额" prop="balance" width="140" show-overflow-tooltip>
            <template #default="{ row }">
              {{ row.balance + ' 万' }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" width="100">
            <template #default="{ row }">
              <el-tag :type="row.status === 1 ? 'success' : 'danger'">
                {{ row.status === 1 ? '正常' : '冻结' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="开通时间" prop="created_at" width="180" show-overflow-tooltip/>
          <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope">
              <router-link style="margin-right: 10px;"
                           :to="{
                            path: '/agents/walletlogs',
                            query: {
                                agent_id: scope.row.agent_id
                            }
                        }"
              >
                <el-button type="primary" :icon="List" plain size="small">查看流水</el-button>
              </router-link>
              <el-button type="warning" plain size="small" @click="onClickEdit(scope.row.id)" :icon="EditPen">
                余额
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" style="margin-top: 10px;">
          <el-pagination background layout="total,prev, pager, next, jumper, slot" :total="pageinfo.total"
                         @current-change="pageChange" :page-size="pageinfo.perPage" :current-page="pageinfo.currentPage"
                         :hide-on-single-page="false">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
  <el-dialog :title="dialogTitle" destroy-on-close width="550px" v-model="dialogVisible">
    <el-form :model="isEditMode ? editForm : addForm" label-width="100px">
      <div v-if="isEditMode">
        <el-form-item label="代理：">
          {{ rowDetail.agent.company + '（' + rowDetail.agent.name + '）' }}
        </el-form-item>
        <el-form-item label="当前余额：">
          {{ rowDetail.balance + ' 万' }}
        </el-form-item>
        <el-form-item label="余额变动：">
          <el-radio-group v-model="editForm.op">
            <el-radio label="add">增加</el-radio>
            <el-radio label="sub">减少</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="变动数值：">
          <el-input-number v-model="editForm.change_num" controls-position="right"
                           :min="0" :max="99999" :precision="2">
          </el-input-number>
          <span style="margin-left: 10px;">万</span>
        </el-form-item>
        <el-form-item label="状态：">
          <el-radio-group v-model="editForm.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">冻结</el-radio>
          </el-radio-group>
          <span v-if="editForm.status === 0" style="color: red;font-size: 12px;">
            注意：冻结余额后，该代理商名下全部商户将无法交易！
          </span>
        </el-form-item>
      </div>
      <div v-if="!isEditMode">
        <el-form-item label="代理：">
          <el-select v-model="addForm.agent_id" filterable clearable placeholder="请选择">
            <el-option v-for="item in agentList" :key="item.value" :label="item.label"
                       :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="余额：">
          <el-input-number v-model="addForm.balance" controls-position="right"
                           :min="0" :max="99999" :precision="2">
          </el-input-number>
          <span style="margin-left: 10px;">万</span>
        </el-form-item>
        <el-form-item label="状态：">
          <el-radio-group v-model="addForm.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">冻结</el-radio>
          </el-radio-group>
          <span v-if="addForm.status === 0" style="color: red;font-size: 12px;">
            注意：冻结余额后，该代理商名下全部商户将无法交易！
          </span>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="onCloseDialog()">取消</el-button>
        <el-button type="primary" @click="isEditMode ? edit() : add()">
          提交保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, reactive, ref} from 'vue';
import {EditPen, List, Plus} from "@element-plus/icons-vue";
import {ElMessage} from "element-plus";

const {proxy} = getCurrentInstance();
const agentList = ref([]);
const form = ref({});
const tableData = ref([]);
let isloading = ref(false);
let pageinfo = ref({
  perPage: 0, // 每页多少条
  total: 0, // 共计多少
  totalPages: 0, // 共计多少页
  currentPage: 1, // 当前页数
});

const pageChange = (page) => {
  hasSearch(page);
};

const hasSearch = (page) => {
  isloading.value = true;
  let info = {...form.value, page: page};
  queryList(info);
};

const handleSearch = () => {
  isloading.value = true;
  pageinfo.value.currentPage = 1; // Reset to the first page
  let info = {...form.value, page: 1};
  queryList(info);
};
const resetSearch = () => {
  form.value = {};
  handleSearch();
};

// 查询代理列表
const queryAgentList = async () => {
  proxy.$api.agentsList({parent_id: "null", pageSize: 999}).then((res) => {
    if (res.status == 200) {
      agentList.value = res.data.map((item) => ({
        value: item.id,
        label: item.company + "（" + item.name + ")",
      }));
    }
  });
};

const queryList = async (data) => {
  const response = await proxy.$api.agents.apiWalletList(data);
  tableData.value = response.data.data;
  pageinfo.value = {
    perPage: response.data.pagination.perPage,
    total: response.data.pagination.total,
    totalPages: response.data.pagination.totalPages,
    currentPage: response.data.pagination.currentPage
  };
  isloading.value = false;
};

// 页面挂载时执行
onMounted(() => {
  handleSearch()
  queryAgentList();
});

const isEditMode = ref(false);
const dialogTitle = computed(() => isEditMode.value ? '代理钱包设置' : '开通代理计费');
const dialogVisible = ref(false);
const editForm = reactive({
  id: '',
  status: 1,
  op: 'add',
  change_num: 0,
});
const addForm = reactive({
  agent_id: '',
  balance: 0,
  status: 1,
});
const resetForm = () => {
  addForm.agent_id = ''
  addForm.balance = 0
  addForm.status = 1
  editForm.id = ''
  editForm.status = 1
  editForm.op = 'add'
  editForm.change_num = 0
};

const onCloseDialog = () => {
  dialogVisible.value = false;
};

const add = () => {
  proxy.$api.agents.apiWalletAdd(addForm).then(res => {
    if (res.status === 200) {
      ElMessage({
        message: '开通成功',
        type: 'success',
      });
      dialogVisible.value = false;
      handleSearch();
    }
  });
};

const edit = () => {
  proxy.$api.agents.apiWalletEdit(editForm).then(res => {
    if (res.status === 200) {
      ElMessage({
        message: '修改成功',
        type: 'success',
      });
      dialogVisible.value = false;
      handleSearch();
    }
  });
};

const rowDetail = ref({});
const onClickEdit = (id) => {
  resetForm()
  proxy.$api.agents.apiWalletDetail({id}).then(res => {
    rowDetail.value = res.data.data;
    setFormFromDetail(rowDetail.value)
    isEditMode.value = true;
    dialogVisible.value = true;
  });
};
const setFormFromDetail = (rowDetail) => {
  for (const key in editForm) {
    if (rowDetail[key] != null && rowDetail[key] != undefined) {
      editForm[key] = rowDetail[key];
    }
  }
};
const onClickAdd = () => {
  resetForm()
  isEditMode.value = false;
  dialogVisible.value = true;
};

</script>
